import { Component, OnInit } from '@angular/core';
import { Api } from 'src/app/const/api';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { AddBrandComponent } from '../add-brand/add-brand.component';
import { environment } from 'src/environments/environment';
import { DialogService } from 'primeng/dynamicdialog';
import { FieldsType } from 'src/app/enums/fields-type.enum';

@Component({
	selector: 'app-categories',
	templateUrl: './brands.component.html',
	styleUrls: ['./brands.component.scss'],
})
export class BrandsComponent implements OnInit {
	columns = [
		{
			field: 'brand_name',
			header: 'Brand Name',
			disableSort: false,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
		},
		{
			field: 'brand_logo',
			header: 'Brand Logo',
			disableSort: false,
			fieldType: FieldsType.Image,
			imageBaseUrl: environment.imageUrl,
			prefix: '',
			suffix: '',
			isEditable: false,
		},
		{
			field: 'brand_cover',
			header: 'Brand Poster',
			disableSort: false,
			fieldType: FieldsType.Image,
			imageBaseUrl: environment.imageUrl,
			prefix: '',
			suffix: '',
			isEditable: false,
		},
		{
			field: 'Actions',
			header: 'Actions',
			disableSort: true,
			fieldType: FieldsType.Action,
		},
	];
	brands: any[] = [];

	readonly imageUrl: string = environment.imageUrl;

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private dialogService: DialogService,
	) { }

	ngOnInit(): void {
		this.getBrands();
	}

	getBrands() {
		this.http.get(Api.GET_BRANDS).subscribe({
			next: (res: any) => {
				this.brands = res;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	deleteBrand = (brandId: string, index: number) => {
		this.http.delete(Api.DELETE_BRAND + brandId).subscribe({
			next: (res: any) => {
				this.brands.splice(index, 1);
			},
			error: (err: any) => {
				this.utilityService.showSuccessToast('Failed to remove brand.');
			},
			complete: () => {
				this.utilityService.showSuccessToast('Brand Removed.');
			},
		});
	}

	openAddBrandDialog = () => {
		const dialogRef = this.dialogService.open(AddBrandComponent, {
			header: 'Add New Brand',
			width: '450px',
			data: {
				type: 'ADD',
			},
		});

		dialogRef.onClose.subscribe((_) => {
			this.getBrands();
		});
	}

	editBrand = (brandData: any, index: any) => {
		const dialogRef = this.dialogService.open(AddBrandComponent, {
			header: 'Edit Brand',
			width: '350px',
			data: {
				type: 'EDIT',
				data: brandData,
			},
		});

		dialogRef.onClose.subscribe((_) => {
			this.getBrands();
		});
	}
}
