import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'app-field-with-label',
	templateUrl: './field-with-label.component.html',
	styleUrls: ['./field-with-label.component.scss'],
	imports: [CommonModule],
})
export class FieldWithLabelComponent {
	@Input('label') label: string = '';
	@Input('required') required: boolean = false;
	@Input('title') title: string = '';
	@Input('description') description: string = '';
}
