import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersComponent } from './orders/orders.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { OrderRoutingModule } from './order-routing.module';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { SimpleTableComponent } from 'src/app/components/simple-table/simple-table.component';
import { FieldWithLabelComponent } from 'src/app/components/field-with-label/field-with-label.component';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
	declarations: [OrdersComponent, OrderDetailsComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(OrderRoutingModule),
		ComponentsModule,
		FormsModule,
		PageHeaderComponent,
		SimpleTableComponent,
		FieldWithLabelComponent,
		CalendarModule,
		ButtonModule,
		InputTextModule,
	],
})
export class OrderModule {}
