<div class="page">
	<form [formGroup]="faqFrom" (ngSubmit)="submitFaqData()">
		<div class="flex flex-col gap-4">
			<app-field-with-label title="FAQ Title" [required]="true">
				<input type="text" pInputText formControlName="title" [required]="true" />
				<span *ngIf="title.invalid && (title.dirty || title?.touched)">
					<span *ngIf="title?.errors?.['required']"> FAQ title is required </span>
				</span>
			</app-field-with-label>

			<app-field-with-label title="FAQ Title" [required]="true">
				<ckeditor [editor]="editor" formControlName="description" placeholder="FAQ Description"></ckeditor>
				<span *ngIf="
					description.invalid &&
					(description.dirty || description?.touched)
				">
					<span *ngIf="description?.errors?.['required']">
						FAQ Description is required
					</span>
				</span>
			</app-field-with-label>

			<app-field-with-label title="FAQ Category" [required]="true">
				<p-dropdown formControlName="category" [options]="categories" optionLabel="faq_category_name"
					optionValue="id" placeholder="Select a Category"></p-dropdown>
				<span *ngIf="category.invalid && (category.dirty || category?.touched)">
					<span *ngIf="category?.errors?.['required']"> FAQ category is required </span>
				</span>
			</app-field-with-label>

			<div class="flex items-center justify-end">
				<button pButton type="submit">Save FAQ</button>
			</div>
		</div>
	</form>
</div>