import { Component, OnInit, ViewChild } from '@angular/core';
import { Api } from 'src/app/const/api';
import { ApiMessages } from 'src/app/const/messages';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import * as dayjs from 'dayjs';
import { ExportService } from 'src/app/services/export/export.service';

import { EmailDialogComponent } from 'src/app/components/email-dialog/email-dialog.component';
import { IndividualEmailDialogComponent } from 'src/app/components/individual-email-dialog/individual-email-dialog.component';
import { NgxCSVParserError, NgxCsvParser } from 'ngx-csv-parser';
import { DialogService } from 'primeng/dynamicdialog';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog/confirmation-dialog.service';

@Component({
	selector: 'app-supplier',
	templateUrl: './supplier.component.html',
	styleUrls: ['./supplier.component.scss'],
})
export class SupplierComponent implements OnInit {
	columns = [
		{
			field: 'company_name',
			header: 'Company Name',
			disableSort: false,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
			class: 'max-w-[180px] truncate',
			showToolTip: true,
		},
		{
			field: 'supplier_name',
			header: 'Supplier Name',
			disableSort: false,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
			class: 'max-w-[180px] truncate',
			showToolTip: true,
		},
		{
			field: 'supplier_email',
			header: 'Email',
			disableSort: false,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
			class: 'max-w-[180px] truncate',
			showToolTip: true,
		},
		{
			field: 'supplier_phone',
			header: 'Phone',
			disableSort: false,
			fieldType: FieldsType.Telephone,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,

		},
		{
			field: 'created_at',
			header: 'Joined On',
			disableSort: false,
			fieldType: FieldsType.Date,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
			dateFormat: `MMM-dd-YYYY`,
		},
		{
			field: 'status',
			header: 'Status',
			disableSort: false,
			fieldType: 'status',
			width: 'auto',
		},
		{
			field: 'Verified',
			header: 'Resend Verification',
			disableSort: false,
			fieldType: FieldsType.LinkButton,
			width: '120px',
			class: 'text-center'
		},
	];
	suppliers: any[] = [];

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private exportService: ExportService,
		private dialogService: DialogService,
		private ngxCsvParser: NgxCsvParser,
		private router: Router,
		private confirmationDialogService: ConfirmationDialogService,
	) { }

	get fieldTypes() {
		return FieldsType
	}

	ngOnInit(): void {
		this.getSuppliers();
	}

	getSuppliers() {
		this.utilityService.showLoading();
		this.http.get(Api.GET_SUPPLIERS).subscribe({
			next: (res: any) => {
				res.map((s: any) => {
					if (s.status == 'A') {
						s.statusText = 'Active';
					} else if (s.status == 'NA') {
						s.statusText = 'Not Active';
					} else if (s.status == 'V') {
						s.statusText = 'Contact for Onboarding';
					} else if (s.status == 'O') {
						s.statusText = 'Onboarding';
					} else if (s.status == 'NV') {
						s.statusText = 'Unverified';
					}
				});
				this.suppliers = res;
			},
			error: (err) => {
				this.utilityService.hideLoading();
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	updateSupplierStatus(event: any, supplierId: string) {
		let params = { status: event.checked ? 'A' : 'NA' };
		try {
			this.utilityService.showLoading();
			this.http.patch(Api.UPDATE_SUPPLIER_STATUS(supplierId), params).subscribe({
				next: (res: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(ApiMessages.SUPPLIER_STATUS_CHANGED);
					this.getSuppliers();
				},
				error: (err: any) => {
					console.log(err);
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast('');
				},
			});
		} catch (error) {
			this.utilityService.hideLoading();
			this.utilityService.showSuccessToast(error);
		}
	}

	deleteProduct(productId: string, index: number) {
		this.http.delete(Api.DELETE_PRODUCT + productId).subscribe({
			next: (res: any) => {
				this.suppliers.splice(index, 1);
				this.utilityService.showSuccessToast('Product deleted.');
			},
			error: (err: any) => {
				this.utilityService.showSuccessToast('Unable to delete product');
			},
		});
	}

	exportSuppliers() {
		const csvColumns = [
			'#',
			'Company',
			'Supplier Name',
			'Email',
			'Phone',
			'Joined On',
			'Status',
		];
		let csvData = [];
		let dataToExport = [];
		dataToExport = this.suppliers;
		let index = 0;
		for (const data of dataToExport) {
			index++;
			csvData.push({
				'#': index,
				Company: data.company_name,
				'Supplier Name': data.supplier_name,
				Email: data.supplier_email,
				Phone: data.supplier_phone,
				'Joined On': dayjs(data.created_at).format('MMMM DD, YYYY'),
				Status: data.statusText,
			});
		}
		this.exportService.exportToCsv(
			csvData,
			`suppliers-${dayjs().format('DD-MM-YYYY')}`,
			csvColumns,
		);
	}

	sendEmail() {
		const dialogRef = this.dialogService.open(EmailDialogComponent, {
			width: '50%',
		});

		dialogRef.onClose.subscribe((params: any) => {
			this.http.post(Api.SEND_EMAIL_TO_SUPPLIERS, params).subscribe({
				next: (res: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(res.data);
				},
				error: (err: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(ApiMessages.SUPPLIER_EMAIL_SENT_FAIL);
				},
				complete: () => { },
			});
		});
	}

	sendIndividualEmail() {
		const emails = this.suppliers.map((data: any) => data.supplier_email);
		const dialogRef = this.dialogService.open(IndividualEmailDialogComponent, {
			width: '50%',
			data: {
				emails: emails,
			},
		});

		dialogRef.onClose.subscribe((params: any) => {
			this.http.post(Api.SEND_INDIVIDUAL_EMAIL, params).subscribe({
				next: (res: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(res.data);
				},
				error: (err: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(ApiMessages.USERS_EMAIL_SENT_FAIL);
				},
				complete: () => { },
			});
		});
	}

	viewSupplier = (rowData: any) => {
		this.router.navigate(['/supplier', rowData.id])
	};

	onCsvFileSelect(event: any) {
		const file = event.target.files[0];
		this.confirmationDialogService.showConfirmationDialog(
			'Import Selected CSV File?',
			`Are you sure you want to import the "<b>${file.name}</b>" file?  <br/>
			<b>It can not be reverted</b>`,
			() => {
				this._processImportCsv(file);
			},
			() => { },
			() => { },
		);
	}

	_processImportCsv(file: File) {
		this.utilityService.showLoading();
		this.ngxCsvParser
			.parse(file, {
				header: false,
				delimiter: ',',
			})
			.pipe()
			.subscribe({
				next: (result: Array<any>) => {
					console.table(result);
					return;
					result.splice(0, 1);
					this.http.post(Api.IMPORT_SUPPLIERS, { suppliers: result }).subscribe({
						next: (res: any) => {
							this.utilityService.hideLoading();
							this.utilityService.showSuccessToast(
								'Suppliers Imported Successfully.',
							);
						},
						error: (err: any) => {
							console.log(err);
							this.utilityService.hideLoading();
							this.utilityService.showErrorToast('Unable to Import Suppliers.');
						},
						complete: () => {
							this.utilityService.hideLoading();
						},
					});
				},
				error: (error: NgxCSVParserError) => {
					console.log('Error', error);
				},
			});
	}

	resendVerification = (rowData: any) => {
		this.utilityService.showLoading();
		this.http.post(Api.RESEND_VERIFICATION_EMAIL_TO_SUPPLIERS, { email: rowData.supplier_email }).subscribe({
			next: (res: any) => {
				this.utilityService.hideLoading();
				this.utilityService.showSuccessToast(res.data);
			},
			error: (err: any) => {
				this.utilityService.hideLoading();
				this.utilityService.showSuccessToast(ApiMessages.SUPPLIER_EMAIL_SENT_FAIL);
			},
			complete: () => { },
		});

	}
}
