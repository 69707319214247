import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppConst } from 'src/app/enums/app-const';
import { UserService } from '../user/user.service';
import { UtilityService } from '../utility/utility.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuardService  {
	constructor(
		private router: Router,
		public userService: UserService,
		private utility: UtilityService,
	) {}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		let accessToken = this.utility.getSession(AppConst.ACCESS_TOKEN);
		if (!accessToken) {
			this.router.navigate(['/']);
			return false;
		} else {
			this.userService.accessToken = accessToken;
			return true;
		}
	}
}
