import { Component, OnInit } from '@angular/core';
import {
	FormArray,
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { Api } from 'src/app/const/api';
import { ApiMessages } from 'src/app/const/messages';
import { CommonApiCallService } from 'src/app/services/common-api-call/common-api-call.service';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import {
	productGrowType,
	productFlowering,
	productClimate,
	landrace,
	domesticated,
	tissueCultures,
	productChemovars,
	productThc,
	productCbd,
	productCbda,
	productCbg,
	productCbga,
	productCbc,
	productTHCP,
	productTHCv,
	productCBDv,
	productCBN,
	productThca,
} from 'src/app/const/product-filters';
import { ProductCostUnit } from 'src/app/enums/produnct-cost-unit';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
	selector: 'app-add-product',
	templateUrl: './add-product.component.html',
	styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent implements OnInit {
	categories: any = [];
	brands: any = [];
	suppliers: any = [];
	productForm: UntypedFormGroup;
	productVariantFrom: UntypedFormGroup;
	selectedMainImage: any = [];
	selectedItemImages: any = [];
	imagePreviews: any = [];
	productMainImagePreview: any;
	productGrowType: any;
	productFlowering: any;
	productClimate: any;
	productLandrace: any;
	productDomesticated: any;
	productTissueCulture: any;
	productChemovars: any;
	productThc: any;
	productCbd: any;
	productCbda: any;
	productCbg: any;
	productCbga: any;
	productCbc: any;
	productThcp: any;
	productThcv: any;
	productCbdv: any;
	productCbn: any;
	GRAM_TO_OZ = 0.035274;

	editor = ClassicEditor;
	editorConfig = {
		placeholder: '',
		toolbar: {
			items: [],
		},
	};
	taxPercent: number = 0;

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private commonApiCall: CommonApiCallService,
		private formBuilder: UntypedFormBuilder,
	) {
		this.productForm = new UntypedFormGroup({
			productName: new UntypedFormControl('', [Validators.required]),
			productDescription: new UntypedFormControl('', [Validators.required]),
			productLongDescription: new UntypedFormControl('', [Validators.required]),
			productVariant: this.formBuilder.array([this.productVariantGroup()]),
			category_id: new UntypedFormControl('', [Validators.required]),
			brand_id: new UntypedFormControl('', [Validators.required]),
			supplier_id: new UntypedFormControl('', [Validators.required]),
			grow_type: new UntypedFormControl(''),
			flowering: new UntypedFormControl(''),
			climate: new UntypedFormControl(''),
			landrace: new UntypedFormControl(''),
			domesticated: new UntypedFormControl(''),
			tissue_culture: new UntypedFormControl(''),
			chemovars: new UntypedFormControl(''),
			thc: new UntypedFormControl(''),
			cbd: new UntypedFormControl(''),
			cbda: new UntypedFormControl(''),
			cbg: new UntypedFormControl(''),
			cbga: new UntypedFormControl(''),
			cbc: new UntypedFormControl(''),
			thcp: new UntypedFormControl(''),
			thcv: new UntypedFormControl(''),
			cbdv: new UntypedFormControl(''),
			cbn: new UntypedFormControl(''),
			// thca: new UntypedFormControl('', [Validators.required]),
			status: new UntypedFormControl(true),
			quality_tested: new UntypedFormControl(false),
			harvest_date_by_latitude: new UntypedFormControl(''),
			allow_pre_order: new UntypedFormControl(false),
		});

		this.productGrowType = productGrowType;
		this.productFlowering = productFlowering;
		this.productClimate = productClimate;
		this.productChemovars = productChemovars;
		this.productLandrace = landrace;
		this.productDomesticated = domesticated;
		this.productTissueCulture = tissueCultures;
		this.productTissueCulture = tissueCultures;
		this.productThc = productThc;
		this.productCbd = productCbd;
		this.productCbda = productCbda;
		this.productCbg = productCbg;
		this.productCbga = productCbga;
		this.productCbc = productCbc;
		this.productThcp = productTHCP;
		this.productThcv = productTHCv;
		this.productCbdv = productCBDv;
		this.productCbn = productCBN;
	}

	get productName() {
		return this.productForm.get('productName');
	}
	get productDescription() {
		return this.productForm.get('productDescription');
	}
	get productLongDescription() {
		return this.productForm.get('productLongDescription');
	}
	get productPrice() {
		return this.productForm.get('productPrice');
	}
	get categoryId() {
		return this.productForm.get('category_id');
	}
	get brandId() {
		return this.productForm.get('brand_id');
	}
	get supplier() {
		return this.productForm.get('supplier_id');
	}
	get growType() {
		return this.productForm.get('grow_type');
	}
	get flowering() {
		return this.productForm.get('flowering');
	}
	get landrace() {
		return this.productForm.get('landrace');
	}
	get domesticated() {
		return this.productForm.get('domesticated');
	}
	get tissueCulture() {
		return this.productForm.get('tissue_culture');
	}
	get chemovars() {
		return this.productForm.get('chemovars');
	}
	get climate() {
		return this.productForm.get('climate');
	}
	get thc() {
		return this.productForm.get('thc');
	}
	get cbd() {
		return this.productForm.get('cbd');
	}
	get cbda() {
		return this.productForm.get('cbda');
	}
	get cbg() {
		return this.productForm.get('cbg');
	}
	get cbga() {
		return this.productForm.get('cbga');
	}
	get cbc() {
		return this.productForm.get('cbc');
	}
	get thcp() {
		return this.productForm.get('thcp');
	}
	get thcv() {
		return this.productForm.get('thcv');
	}
	get cbdv() {
		return this.productForm.get('cbdv');
	}
	get cbn() {
		return this.productForm.get('cbn');
	}
	get thca() {
		return this.productForm.get('thca');
	}
	get status() {
		return this.productForm.get('status');
	}
	get qualityTested() {
		return this.productForm.get('quality_tested');
	}
	get harvestDateByLatitude() {
		return this.productForm.get('harvest_date_by_latitude');
	}
	get allowPreOrder() {
		return this.productForm.get('allow_pre_order');
	}

	get ProductCostUnit() {
		return ProductCostUnit;
	}

	ngOnInit(): void {
		this.getCategories();
		this.getBrands();
		this.getSuppliers();
		this.getTaxPercent();
	}

	productVariantGroup(): UntypedFormGroup {
		return this.formBuilder.group({
			id: new UntypedFormControl(''),
			variant_name: new UntypedFormControl('', [Validators.required]),
			// variant_description: new UntypedFormControl(''),
			variant_unit_cost: new UntypedFormControl('', [Validators.required]),
			variant_quantity: new UntypedFormControl('', [Validators.required]),
			product_price: new UntypedFormControl('', [Validators.required]),
			tax_amount: new UntypedFormControl(0),
			price_with_tax: new UntypedFormControl(0, [Validators.required]),
			product_discount_percentage: new UntypedFormControl('0'),
			product_discount_price: new UntypedFormControl('00.00'),
			discount_price_with_tax: new UntypedFormControl('00.00'),
			variant_weight_gram: new UntypedFormControl('', [Validators.required]),
			variant_weight_oz: new UntypedFormControl('', [Validators.required]),
			variant_unit_type: new UntypedFormControl(ProductCostUnit.PERCENT, [
				Validators.required,
			]),
			cogs: new UntypedFormControl('00.00', [Validators.required]),
		});
	}

	calculateDiscountedPrice(index: number) {
		const { product_price, product_discount_percentage } =
			this.productForm.get('productVariant').value[index];
		if (product_price) {
			let existingFormValues = this.productForm.get('productVariant').value;
			if (product_discount_percentage) {
				const discountedAmount = (
					(Number(product_price) * Number(product_discount_percentage)) /
					100
				).toFixed(2);
				const discountedPrice = Number(product_price) - Number(discountedAmount);
				existingFormValues[index].product_discount_price = discountedPrice;
			}

			this.productForm.get('productVariant').patchValue(existingFormValues, {
				emitEvent: false,
			});
		}
	}

	calculateWeightOz(index: number) {
		const { variant_weight_gram } = this.productForm.get('productVariant').value[index];

		if (variant_weight_gram) {
			const weightOz = (Number(variant_weight_gram) * this.GRAM_TO_OZ).toFixed(2);
			let existingFormValues = this.productForm.get('productVariant').value;
			existingFormValues[index].variant_weight_oz = weightOz;
			this.productForm.get('productVariant').patchValue(existingFormValues, {
				emitEvent: false,
			});
		}
	}

	getCategories() {
		this.commonApiCall.getCategories().subscribe({
			next: (res) => {
				this.categories = res;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	getBrands() {
		this.http.get(Api.GET_BRANDS).subscribe({
			next: (res) => {
				this.brands = res;
			},
			error: (err) => {
				console.log(err);
			},
		});
	}

	get variantArray(): UntypedFormArray {
		return <UntypedFormArray>this.productForm.get('productVariant');
	}

	addVariant(): void {
		this.variantArray.push(this.productVariantGroup());
	}

	removeVariant(index: number): void {
		this.variantArray.removeAt(index);
	}

	addProduct() {
		try {
			if (this.productForm.valid) {
				if (!this.selectedMainImage) {
					throw 'Product Main image is required';
				} else if (this.selectedItemImages.length == 0) {
					throw 'Product images are required';
				} else {
					this.utilityService.showLoading();
					let {
						category_id,
						brand_id,
						supplier_id,
						productName,
						productDescription,
						productLongDescription,
						productVariant,
						grow_type,
						flowering,
						climate,
						landrace,
						domesticated,
						tissue_culture,
						chemovars,
						thc,
						cbd,
						cbda,
						cbg,
						cbga,
						cbc,
						thcp,
						thcv,
						cbdv,
						cbn,
						// thca,
						status,
						quality_tested, allow_pre_order
					} = this.productForm.value;

					// for (const index in productVariant) {
					//   delete productVariant[index].product_discount_price;
					// }

					const _formData: any = new FormData();
					for (const img of this.selectedItemImages) {
						_formData.append('images', img, img.name);
					}

					if (this.selectedMainImage && this.selectedMainImage.name) {
						_formData.append(
							'product_main_image',
							this.selectedMainImage,
							this.selectedMainImage.name,
						);
					}

					_formData.append('category_id', category_id);
					_formData.append('brand_id', brand_id);
					_formData.append('supplier_id', supplier_id.id);
					_formData.append('product_name', productName);
					_formData.append('product_description', productDescription);
					_formData.append('product_long_description', productLongDescription);
					_formData.append('product_variant', JSON.stringify(productVariant));
					_formData.append('grow_type', grow_type);
					_formData.append('flowering', flowering);
					_formData.append('climate', climate);
					_formData.append('landrace', landrace);
					_formData.append('domesticated', domesticated);
					_formData.append('tissue_culture', tissue_culture);
					_formData.append('chemovars', chemovars);
					_formData.append('thc', thc);
					_formData.append('cbd', cbd);
					_formData.append('cbda', cbda);
					_formData.append('cbg', cbg);
					_formData.append('cbga', cbga);
					_formData.append('cbc', cbc);
					_formData.append('thcp', thcp);
					_formData.append('thcv', thcv);
					_formData.append('cbdv', cbdv);
					_formData.append('cbn', cbn);
					// _formData.append('thca', thca);
					_formData.append('status', status == true ? '1' : '0');
					_formData.append('quality_tested', quality_tested == true ? '1' : '0');
					_formData.append('allow_pre_order', allow_pre_order == true ? '1' : '0');

					this.http.post(Api.ADD_PRODUCT, _formData).subscribe({
						next: (res: any) => {
							this.productForm.reset();
							this.productForm.clearValidators();
							this.utilityService.hideLoading();
							this.utilityService.showSuccessToast(ApiMessages.PRODUCT_ADDED);
							this.selectedItemImages = [];
							this.imagePreviews = [];
							this.selectedMainImage = null;
							this.productMainImagePreview = null;
						},
						error: (err: any) => {
							console.log(err);
							this.utilityService.hideLoading();
							this,
								this.utilityService.showErrorToast(ApiMessages.PRODUCT_ADD_ERROR);
						},
						complete: () => { },
					});
				}
			} else {
				throw 'Some product details are invalid';
			}
		} catch (error) {
			this.utilityService.hideLoading();
			this.utilityService.showErrorToast(error);
		}
	}

	onProductMainImageSelect(event) {
		this.selectedMainImage = event.target.files[0];
		if (event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (_event) => {
				this.productMainImagePreview = reader.result;
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	}

	onImagesSelect(event) {
		if (event.target.files.length > 4) {
			this.utilityService.showSuccessToast('Maximum 4 images are allowed');
		} else {
			const numberOfFiles = event.target.files.length;
			for (let i = 0; i < numberOfFiles; i++) {
				this.selectedItemImages.push(event.target.files[i]);
				const reader = new FileReader();
				reader.onload = (_event) => {
					this.imagePreviews.push(reader.result);
				};
				reader.readAsDataURL(event.target.files[i]);
			}
		}
	}

	removeMainImage() {
		this.productMainImagePreview = null;
		this.selectedMainImage = null;
	}

	removeImage(index: number) {
		this.imagePreviews.splice(index, 1);
		// this.selectedItemImages.splice(index, 1);
	}

	getSuppliers() {
		this.utilityService.showLoading();
		this.http.get(Api.GET_SUPPLIERS).subscribe({
			next: (res) => {
				this.suppliers = res;
			},
			error: (err) => {
				this.utilityService.hideLoading();
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	calculateCOGS(index: number) {
		const {
			variant_unit_type,
			variant_unit_cost,
			product_discount_percentage,
			product_discount_price,
		} = this.productForm.get('productVariant').value[index];

		if (variant_unit_cost) {
			if (variant_unit_type == ProductCostUnit.AMOUNT) {
				const calculatedCogs = (
					Number(variant_unit_cost) *
					((100 - product_discount_percentage) / 100)
				).toFixed(2);
				let existingFormValues = this.productForm.get('productVariant').value;
				existingFormValues[index].cogs = calculatedCogs;
				this.productForm.get('productVariant').patchValue(existingFormValues, {
					emitEvent: false,
				});
			} else if (variant_unit_type == ProductCostUnit.PERCENT) {
				const calculatedCogs = (
					Number(product_discount_price) *
					(Number(variant_unit_cost) / 100)
				).toFixed(2);
				let existingFormValues = this.productForm.get('productVariant').value;
				existingFormValues[index].cogs = calculatedCogs;
				this.productForm.get('productVariant').patchValue(existingFormValues, {
					emitEvent: false,
				});
			}
		}
	}

	getTaxPercent() {
		this.http.get(Api.GET_TAX_PERCENT).subscribe({
			next: (res: any) => {
				this.taxPercent = Number(res[0].tax);
			},
			error: (err: any) => {
			}
		});
	}

	calculatePriceWithTax(index: number) {
		const { product_price, product_discount_percentage, product_discount_price } =
			this.productForm.get('productVariant').value[index];
		let taxAmount = 0;
		let priceWithTax = 0;
		let discountPriceWithTax = 0;
		if (product_price) {
			taxAmount = Number(product_price) * (this.taxPercent / 100);
			priceWithTax = Number(product_price) + taxAmount;
		}

		if (product_discount_percentage && product_discount_price) {
			taxAmount = Number(product_discount_price) * (this.taxPercent / 100);
			discountPriceWithTax = Number(product_discount_price) + taxAmount;
		}

		let existingFormValues = this.productForm.get('productVariant').value;
		existingFormValues[index].tax_amount = taxAmount;
		existingFormValues[index].price_with_tax = priceWithTax;
		existingFormValues[index].discount_price_with_tax = discountPriceWithTax;
		this.productForm.get('productVariant').patchValue(existingFormValues, {
			emitEvent: false,
		});
	}
}
