export class ApiMessages {
	static PRODUCT_ADDED = 'Product added successfully.';
	static PRODUCT_ADD_ERROR = 'Unable to add product.';
	static PRODUCT_UPDATED = 'Product details updated successfully.';
	static PRODUCT_UPDATE_ERROR = 'Unable to update product details.';
	static PRODUCT_IMAGE_REMOVED = 'Product image removed';

	static SUPPLIER_APPROVED = 'Supplier Approved.';
	static SUPPLIER_STATUS_CHANGED = 'Supplier Status Changed.';
	static SUPPLIER_DETAILS_UPDATED = 'Supplier Details Updated.';

	static BLOG_ADDED = 'Blog posted successfully.';
	static BLOG_ADD_ERROR = 'Unable to post blog.';
	static BLOG_UPDATE = 'Blog updated successfully.';
	static BLOG_UPDATE_ERROR = 'Unable to update blog.';

	static SUBSCRIBER_EMAIL_SENT = 'Email has been sent to the subscribers';
	static SUBSCRIBER_EMAIL_SENT_FAIL = 'Unable to sent an email to the subscribers';
	static USERS_EMAIL_SENT_FAIL = 'Unable to sent an email to the consumers';
	static SUPPLIER_EMAIL_SENT_FAIL = 'Unable to sent an email to the Suppliers.';

	static SHIPPING_MANAGER_EMAIL_SAVED = 'Shipping manager email saved.';
	static SHIPPING_MANAGER_EMAIL_SAVE_FAIL = 'Unable to save Shipping manager email.';
	static SHIPPING_CHARGES_SAVED = 'Shipping charges saved.';
}
