<div class="page">
	<app-page-header header="Edit Product | {{productData?.product_name}}"></app-page-header>
	<div class="add-product-container">
		<form [formGroup]="productForm" (ngSubmit)="updateProduct()">
			<h2 class="text-xl font-semibold">Product Description</h2>
			<div class="grid grid-cols-2 gap-3 border-b border-gray-300 py-2">
				<div>
					<div class="form-field">
						<div class="field-title-container">
							<p>Product name:</p>
						</div>
						<div class="form-field-container">
							<input type="text" pInputText formControlName="productName" [required]="true" />
							<span *ngIf="productName.invalid && (productName.dirty || productName?.touched)">
								<span *ngIf="productName?.errors?.['required']">
									Product name is required
								</span>
							</span>
						</div>
					</div>

					<div class="form-field">
						<div class="field-title-container">
							<p>Short description:</p>
							<span>Short description 30-40 words (visible)</span>
						</div>
						<div class="form-field-container">
							<textarea pInputText formControlName="productDescription" [required]="true" rows="3"
								class="resize-none" style="height: 74px"></textarea>
							<span
								*ngIf="productDescription.invalid && (productDescription.dirty || productDescription?.touched)">
								<span *ngIf="productDescription?.errors?.['required']">
									Short Description is required
								</span>
							</span>
						</div>
					</div>

					<div class="form-field">
						<div class="field-title-container">
							<p>Supplier:</p>
						</div>
						<div class="form-field-container">
							<p-dropdown formControlName="supplier_id" [options]="suppliers"
								placeholder="Select a Supplier">
								<ng-template pTemplate="selectedItem">
									<span *ngIf="supplier.value">
										{{ supplier?.value.company_name }} - {{ supplier?.value.supplier_name }}
									</span>
								</ng-template>
								<ng-template let-supplier pTemplate="item">
									{{ supplier.company_name }} - {{ supplier.supplier_name }}
								</ng-template>
							</p-dropdown>

							<span *ngIf="supplierId.invalid && (supplierId.dirty || supplierId?.touched)">
								<span *ngIf="supplierId?.errors?.['required']">
									Product Supplier is required
								</span>
							</span>
						</div>
					</div>
				</div>

				<div>
					<div class="form-field">
						<div class="field-title-container">
							<p>Long description:</p>
							<span>Long description 300-400 words (visible)</span>
						</div>
						<div class="form-field-container">
							<textarea pInputText formControlName="productLongDescription" required
								class="resize-none h-full w-full" rows="6"></textarea>
							<span
								*ngIf="productLongDescription.invalid && (productLongDescription.dirty || productLongDescription?.touched)">
								<span *ngIf="productLongDescription?.errors?.['required']">
									Long description is required
								</span>
							</span>
						</div>
					</div>
				</div>
			</div>

			<div class="border-b border-gray-300 py-2">
				<div class="flex items-center justify-between">
					<h2 class="text-xl font-semibold">Packing & Pricing</h2>
					<button pButton type="button" pTooltip="Add new Variant Fields" class="w-auto"
						(click)="addVariant()">
						Add New
					</button>
				</div>
				<div class="">
					<!-- <div class="field-title-container">
						<p>No of Seed:</p>
						<span>Add seeds per pack(visible)</span>
					</div> -->
					<div class="form-field-container">
						<div class="product-variant mb-2">
							<div class="product-variant-title">
								<span>Cost Type</span>
								<span>Unit Cost</span>
								<span>Stock Open(Packs)</span>
								<span class="ml-6">Seeds Per Pack</span>
								<span class="ml-6">Weight(g)</span>
								<span class="mr-6">Weight(oz)</span>
								<span>Original Price</span>
								<span>Discount(%)</span>
								<span>Discounted Price</span>
								<span>Tax</span>
								<span>Discount Price With Tax</span>
								<span>COGS</span>
							</div>
							<div class="product-variant-title">
								<span>Action</span>
							</div>
						</div>
						<div *ngFor="let group of variantArray?.controls; let i = index" formArrayName="productVariant"
							class="product-variant">
							<div class="product-variant-fields mb-2" [formGroupName]="i">
								<div class="flex gap-2 items-center">
									<div class="flex gap-2 items-center">
										<p-radioButton formControlName="variant_unit_type" inputId="%"
											[value]="ProductCostUnit.PERCENT" (onClick)="calculateCOGS(i)">
										</p-radioButton>
										<label for="%">%</label>
									</div>

									<div class="flex gap-2 items-center">
										<p-radioButton formControlName="variant_unit_type" inputId="$"
											[value]="ProductCostUnit.AMOUNT" (onClick)="calculateCOGS(i)">
										</p-radioButton>
										<label for="$">$</label>
									</div>
								</div>

								<input pInputText formControlName="variant_unit_cost" placeholder="Unit Cost" required
									mask="0*.00" thousandSeparator="," decimalMarker="." inputmode="numeric"
									[dropSpecialCharacters]="false" (change)="calculateCOGS(i)" />

								<input pInputText formControlName="variant_quantity" placeholder="Stock Open(Packs)"
									required mask="99*" />

								<input pInputText formControlName="variant_name" required mask="99*"
									placeholder="Seeds per pack" class="ml-6" />

								<input pInputText formControlName="variant_weight_gram" placeholder="Gross Weight(g)"
									(change)="calculateWeightOz(i)" required mask="0*.00" thousandSeparator=","
									decimalMarker="." inputmode="numeric" [dropSpecialCharacters]="false"
									class="ml-6" />

								<input pInputText formControlName="variant_weight_oz" placeholder="Gross Weight(oz)"
									readonly mask="0*.00" thousandSeparator="," decimalMarker="." inputmode="numeric"
									[dropSpecialCharacters]="false" class="mr-6 read-only-field" />

								<input pInputText formControlName="product_price" placeholder="Price (00.00)" required
									(change)="calculateDiscountedPrice(i); calculateCOGS(i); calculatePriceWithTax(i)"
									mask="0*.00" thousandSeparator="," decimalMarker="." inputmode="numeric"
									[dropSpecialCharacters]="false" />

								<input pInputText formControlName="product_discount_percentage"
									placeholder="Discount(%)"
									(change)="calculateDiscountedPrice(i); calculateCOGS(i); calculatePriceWithTax(i)"
									mask="99.99" thousandSeparator="," decimalMarker="." inputmode="numeric"
									[dropSpecialCharacters]="false" />


								<input pInputText formControlName="product_discount_price" placeholder="Discount Price"
									readonly mask="separator.2" thousandSeparator="" decimalSeparator="."
									[dropSpecialCharacters]="false" class="read-only-field" />

								<!-- <input type="hidden" formControlName="tax_amount" readonly
									class="opacity-0 invisible" />

								<input pInputText formControlName="price_with_tax" placeholder="Price With Tax" readonly
									mask="separator.2" thousandSeparator="" decimalSeparator="."
									[dropSpecialCharacters]="false" class="read-only-field" /> -->

								<input pInputText formControlName="tax_amount" placeholder="Tax" readonly
									mask="separator.2" thousandSeparator="" decimalSeparator="."
									[dropSpecialCharacters]="false" class="read-only-field" />

								<input type="hidden" formControlName="price_with_tax" readonly
									class="opacity-0 invisible" />

								<input pInputText formControlName="discount_price_with_tax"
									placeholder="Discount Price With Tax" readonly mask="separator.2"
									thousandSeparator="" decimalSeparator="." [dropSpecialCharacters]="false"
									class="read-only-field" />

								<input pInputText formControlName="cogs" placeholder="COGS" readonly mask="separator.2"
									thousandSeparator="" decimalSeparator="." [dropSpecialCharacters]="false"
									class="read-only-field" />
							</div>

							<div class="variant-action-container">
								<button pButton pRipple type="button" pTooltip="Delete" icon="pi pi-trash"
									(click)="removeVariant(i)" *ngIf="variantArray?.controls.length > 1"
									class="p-button-rounded p-button-text">
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="border-b border-gray-300 py-2">
				<div class="flex">
					<div class="w-full border-r border-gray-300 space-y-4 pr-12">
						<div class="flex items-center justify-between">
							<h2 class="text-xl font-semibold">Specifications</h2>
						</div>
						<div class="form-field">
							<div class="field-title-container">
								<p>Category:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="category_id" [options]="categories"
									optionLabel="category_name" optionValue="id" placeholder="Select a Category"
									[required]="true">
								</p-dropdown>

								<span *ngIf="
										categoryId.invalid &&
										(categoryId.dirty || categoryId?.touched)
									">
									<span *ngIf="categoryId?.errors?.['required']">
										Product Category is required
									</span>
								</span>
							</div>
						</div>

						<div class="form-field">
							<div class="field-title-container">
								<p>Brand:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="brand_id" [options]="brands" optionLabel="brand_name"
									optionValue="id" placeholder="Select a Brand" [required]="true">
								</p-dropdown>
								<span *ngIf="brandId.invalid && (brandId.dirty || brandId?.touched)">
									<span *ngIf="brandId?.errors?.['required']">
										Product brand is required
									</span>
								</span>
							</div>
						</div>

						<div class="form-field mt-0">
							<div class="field-title-container">
								<p>Grow Type:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="grow_type" [options]="productGrowType" optionLabel="title"
									optionValue="value" placeholder="Select a Grow Type">
								</p-dropdown>
								<!-- <span *ngIf="growType.invalid && (growType.dirty || growType?.touched)">
									<span *ngIf="growType?.errors?.['required']">
										Product Grow type is required
									</span>
								</span> -->
							</div>
						</div>

						<div class="form-field mt-0">
							<div class="field-title-container">
								<p>Flowering:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="flowering" [options]="productFlowering" optionLabel="title"
									optionValue="value" placeholder="Select a Grow Type">
								</p-dropdown>
								<!-- <span *ngIf="flowering.invalid && (flowering.dirty || flowering?.touched)">
									<span *ngIf="flowering?.errors?.['required']">
										Product Flowering duration is required
									</span>
								</span> -->
							</div>
						</div>

						<div class="form-field mt-0">
							<div class="field-title-container">
								<p>Climate:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="climate" [options]="productClimate" optionLabel="title"
									optionValue="value" placeholder="Select a Climate">
								</p-dropdown>
								<!-- <span *ngIf="climate.invalid && (climate.dirty || climate?.touched)">
									<span *ngIf="climate?.errors?.['required']">
										Product Climate is required
									</span>
								</span> -->
							</div>
						</div>

						<div class="form-field mt-0">
							<div class="field-title-container">
								<p>Classification:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="landrace" [options]="productLandrace" optionLabel="title"
									optionValue="value" placeholder="Select a Classification">
								</p-dropdown>
								<!-- <span *ngIf="landrace.invalid && (landrace.dirty || landrace?.touched)">
									<span *ngIf="landrace?.errors?.['required']">
										Product Landrace is required
									</span>
								</span> -->
							</div>
						</div>

						<div class="form-field mt-0">
							<div class="field-title-container">
								<p>Domesticated:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="domesticated" [options]="productDomesticated"
									optionLabel="title" optionValue="value" placeholder="Select a Domesticated">
								</p-dropdown>
								<!-- <span
									*ngIf="domesticated.invalid && (domesticated.dirty || domesticated?.touched)">
									<span *ngIf="domesticated?.errors?.['required']">
										Product domesticated is required
									</span>
								</span> -->
							</div>
						</div>

						<div class="form-field mt-0">
							<div class="field-title-container">
								<p>Tissue Culture:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="tissue_culture" [options]="productTissueCulture"
									optionLabel="title" optionValue="value" placeholder="Select a Tissue Culture">
								</p-dropdown>
								<!-- <span
									*ngIf="tissueCulture.invalid && (tissueCulture.dirty || tissueCulture?.touched)">
									<span *ngIf="tissueCulture?.errors?.['required']">
										Product tissue culture is required
									</span>
								</span> -->
							</div>
						</div>

						<div class="form-field mt-0">
							<div class="field-title-container">
								<p>Chemovars:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="chemovars" [options]="productChemovars" optionLabel="title"
									optionValue="value" placeholder="Select a Chemovars">
								</p-dropdown>
								<!-- <span *ngIf="chemovars.invalid && (chemovars.dirty || chemovars?.touched)">
									<span *ngIf="chemovars?.errors?.['required']">
										Product Chemovars is required
									</span>
								</span> -->
							</div>
						</div>
						<div class="form-field mt-0">
							<div class="field-title-container">
								<p>Harvest Date By Latitude:</p>
							</div>
							<div class="form-field-container">
								<ckeditor [editor]="editor" [config]="editorConfig"
									formControlName="harvest_date_by_latitude" class="input-style w-full !p-0 h-full">
								</ckeditor>
							</div>
						</div>
					</div>

					<div class="w-full space-y-4">
						<div class="flex items-center justify-between">
							<h2 class="text-xl font-semibold pl-20">Cannabinoids</h2>
						</div>
						<div class="form-field">
							<div class="field-title-container pl-20">
								<p>THC:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="thc" [options]="productThc" optionLabel="title"
									optionValue="value" placeholder="Select a THC">
								</p-dropdown>
								<span *ngIf="thc.invalid && (thc.dirty || thc?.touched)">
									<span *ngIf="thc?.errors?.['required']">
										Product THC is required
									</span>
								</span>
							</div>
						</div>
						<div class="form-field mt-0">
							<div class="field-title-container pl-20">
								<p>CBD:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="cbd" [options]="productCbd" optionLabel="title"
									optionValue="value" placeholder="Select a CBD">
								</p-dropdown>
								<span *ngIf="cbd.invalid && (cbd.dirty || cbd?.touched)">
									<span *ngIf="cbd?.errors?.['required']">
										Product CBD is required
									</span>
								</span>
							</div>
						</div>

						<div class="form-field mt-0">
							<div class="field-title-container pl-20">
								<p>CBDA:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="cbda" [options]="productCbda" optionLabel="title"
									optionValue="value" placeholder="Select a CBDA">
								</p-dropdown>
								<span *ngIf="cbda.invalid && (cbda.dirty || cbda?.touched)">
									<span *ngIf="cbda?.errors?.['required']">
										Product CBDA is required
									</span>
								</span>
							</div>
						</div>

						<div class="form-field mt-0">
							<div class="field-title-container pl-20">
								<p>CBG:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="cbg" [options]="productCbg" optionLabel="title"
									optionValue="value" placeholder="Select a CBG">
								</p-dropdown>
								<span *ngIf="cbg.invalid && (cbg.dirty || cbg?.touched)">
									<span *ngIf="cbg?.errors?.['required']">
										Product CBG is required
									</span>
								</span>
							</div>
						</div>

						<div class="form-field mt-0">
							<div class="field-title-container pl-20">
								<p>CBGA:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="cbga" [options]="productCbga" optionLabel="title"
									optionValue="value" placeholder="Select a CBGA">
								</p-dropdown>
								<span *ngIf="cbga.invalid && (cbga.dirty || cbga?.touched)">
									<span *ngIf="cbga?.errors?.['required']">
										Product CBGA is required
									</span>
								</span>
							</div>
						</div>

						<div class="form-field mt-0">
							<div class="field-title-container pl-20">
								<p>CBC:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="cbc" [options]="productCbc" optionLabel="title"
									optionValue="value" placeholder="Select a CBC">
								</p-dropdown>
								<span *ngIf="cbc.invalid && (cbc.dirty || cbc?.touched)">
									<span *ngIf="cbc?.errors?.['required']">
										Product CBC is required
									</span>
								</span>
							</div>
						</div>

						<div class="form-field mt-0">
							<div class="field-title-container pl-20">
								<p>THCP:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="thcp" [options]="productThcp" optionLabel="title"
									optionValue="value" placeholder="Select a THCP">
								</p-dropdown>
								<span *ngIf="thcp.invalid && (thcp.dirty || thcp?.touched)">
									<span *ngIf="thcp?.errors?.['required']">
										Product THCP is required
									</span>
								</span>
							</div>
						</div>

						<div class="form-field mt-0">
							<div class="field-title-container pl-20">
								<p>THCV:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="thcv" [options]="productThcv" optionLabel="title"
									optionValue="value" placeholder="Select a THCV">
								</p-dropdown>
								<span *ngIf="thcv.invalid && (thcv.dirty || thcv?.touched)">
									<span *ngIf="thcv?.errors?.['required']">
										Product THCV is required
									</span>
								</span>
							</div>
						</div>

						<div class="form-field mt-0">
							<div class="field-title-container pl-20">
								<p>CBDV:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="cbdv" [options]="productCbdv" optionLabel="title"
									optionValue="value" placeholder="Select a CBDV">
								</p-dropdown>
								<span *ngIf="cbdv.invalid && (cbdv.dirty || cbdv?.touched)">
									<span *ngIf="cbdv?.errors?.['required']">
										Product CBDV is required
									</span>
								</span>
							</div>
						</div>

						<div class="form-field mt-0">
							<div class="field-title-container pl-20">
								<p>CBN:</p>
							</div>
							<div class="form-field-container">
								<p-dropdown formControlName="cbn" [options]="productCbn" optionLabel="title"
									optionValue="value" placeholder="Select a CBN">
								</p-dropdown>
								<span *ngIf="cbn.invalid && (cbn.dirty || cbn?.touched)">
									<span *ngIf="cbn?.errors?.['required']">
										Product CBN is required
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="border-b border-gray-300 py-2 grid grid-cols-3">
				<div class="col-span-2">
					<div class="flex items-center justify-between">
						<h2 class="text-xl font-semibold">Product Images</h2>
					</div>
					<div class="form-field mb-8">
						<div class="field-title-container">
							<p>Product Default Image:</p>
							<span>Product Default images visible to consumer</span>
						</div>
						<div class="form-field-container">
							<div class="image-container">
								<div class="selected-image-container"
									*ngIf="existingProductMainImage && !productMainImagePreview">

									<p-button icon="pi pi-times" [rounded]="true" severity="danger"
										(click)="removeMainImage()" pTooltip="Remove default image" size="small"
										styleClass="remove-button">
									</p-button>

									<img [src]="imageUrl + existingProductMainImage" alt="Select product images" />
								</div>

								<div class="selected-image-container" *ngIf="productMainImagePreview">

									<p-button icon="pi pi-times" [rounded]="true" severity="danger"
										(click)="removeMainImage()" pTooltip="Remove default image" size="small"
										styleClass="remove-button">
									</p-button>

									<img [src]="productMainImagePreview" alt="Select product images" />
								</div>

								<img src="/assets/images/add.png" alt="Select product images" class="add-image"
									(click)="mainImage.click()" matTooltip="Select product images"
									*ngIf="!existingProductMainImage && !productMainImagePreview" />

								<input type="file" class="file-input" #mainImage
									(change)="onProductMainImageSelect($event)" accept="image/png, image/jpeg" />
							</div>
						</div>
					</div>

					<div class="form-field">
						<div class="field-title-container">
							<p>Product Images:</p>
							<span>Product images visible to consumer</span>
						</div>
						<div class="form-field-container">
							<div class="image-container">
								<div class="selected-image-container"
									*ngFor="let img of existingProductImages; let i = index">

									<p-button icon="pi pi-times" [rounded]="true" severity="danger"
										(click)="removeExistingImage(i, img.imageId)" pTooltip="Remove default image"
										size="small" styleClass="remove-button">
									</p-button>

									<img [src]="imageUrl + img.image" alt="Select product images" />
								</div>

								<div class="selected-image-container" *ngFor="let img of imagePreviews; let i = index">

									<p-button icon="pi pi-times" [rounded]="true" severity="danger"
										(click)="removeImage(i)" pTooltip="Remove default image" size="small"
										styleClass="remove-button">
									</p-button>

									<img [src]="img" alt="Select product images" />
								</div>

								<img src="/assets/images/add.png" alt="Select product images" class="add-image"
									(click)="image.click()" matTooltip="Select product images" />

								<input type="file" class="file-input" #image (change)="onImagesSelect($event)"
									accept="image/png, image/jpeg" multiple />
							</div>
						</div>
					</div>
				</div>


				<div class="flex flex-col gap-8">
					<div>
						<div class="flex items-center justify-between">
							<h2 class="text-xl font-semibold">Product Status</h2>
						</div>

						<div class="form-field border rounded-sm p-2">
							<div class="field-title-container">
								<!-- <p>Product Status: </p> -->
								<span class="mt-0">Enable / Disable product availability to consumer</span>
							</div>
							<div class="flex gap-2 items-center">
								<p-inputSwitch formControlName="status"></p-inputSwitch>
								{{ status.value ? 'Enable' : 'Disabled' }}
							</div>
						</div>
					</div>

					<div>
						<div>
							<div class="flex items-center justify-between">
								<h2 class="text-xl font-semibold">Product Quality</h2>
							</div>
							<div class="form-field border rounded-sm p-2">
								<div class="field-title-container">
									<span class="mt-0">
										Enable / Disable product Quality Test to consumer
									</span>
								</div>
								<div class="flex gap-2 items-center">
									<p-inputSwitch formControlName="quality_tested"></p-inputSwitch>
									{{ qualityTested.value ? 'Quality Tested' : 'Not Tested' }}
								</div>
							</div>
						</div>
					</div>
					<div>
						<div>
							<div class="flex items-center justify-between">
								<h2 class="text-xl font-semibold">Accept Pre Orders</h2>
							</div>
							<div class="form-field border rounded-sm p-2 items-center">
								<div class="field-title-container">
									<span class="mt-0">
										Enable to accept pre orders
									</span>
								</div>
								<div class="flex gap-2 items-center">
									<p-inputSwitch formControlName="allow_pre_order"></p-inputSwitch>
									{{ allowPreOrder.value ? 'Accepting Pre Orders' : 'Not Accepting Pre Orders' }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="flex mt-2 justify-end">
				<button pButton type="submit" class="w-auto">
					Save
				</button>
			</div>
		</form>
	</div>
</div>