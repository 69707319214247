<div class="page">
	<app-page-header header="User | {{ userData?.first_name }} {{ userData?.last_name }}"></app-page-header>

	<div class="" *ngIf="userData">
		<div class="border-b border-gray-300 py-2">
			<div class="flex">
				<div class="w-full border-r border-gray-300">
					<div class="flex items-center justify-between">
						<h2 class="text-xl font-semibold pt-2 pb-1">Personal Details</h2>
					</div>
					<div class="detail-fields">
						<p>Name:</p>
						<p>{{ userData.first_name }} {{ userData.last_name }}</p>
					</div>

					<div class="detail-fields">
						<p>Email:</p>
						<p class="underline">
							<a href="mailto:{{ userData.email }}">
								{{ userData.email }}
							</a>
						</p>
					</div>

					<div class="detail-fields">
						<p>Supplier Phone:</p>
						<p class="underline">
							<a href="tel:{{ userData.phone_country }}{{ userData.mobile }}">{{
								userData.mobile
								}}</a>
						</p>
					</div>

					<div class="detail-fields">
						<p>Registered On:</p>
						<p>{{ userData.created_at | date : 'medium' }}</p>
					</div>
				</div>

				<div class="w-full pl-4">
					<div class="flex items-center justify-between">
						<h2 class="text-xl font-semibold pt-2 pb-1">Orders Summary</h2>
					</div>
					<div class="detail-fields">
						<p>Number of Orders</p>
						<p>{{ userData.total_orders }}</p>
					</div>

					<div class="detail-fields">
						<p>Order Total:</p>
						<p>${{ userData.order_total | number : '1.2-2' }}</p>
					</div>
				</div>
			</div>
		</div>

		<div class="border-b border-gray-300 py-2">
			<div class="">
				<h2 class="text-xl font-semibold pt-2 pb-1">Orders</h2>
			</div>

			<app-simple-table [columns]="columns" [data]="orders" [paginator]="true" [showFilter]="false"
				[showActions]="false" [onRowClick]="orderDetails">
			</app-simple-table>
		</div>
	</div>
</div>