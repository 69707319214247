import { ChangeDetectorRef, Component } from '@angular/core';
import { ProgressEventService } from './services/progress-event/progress-event.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	isLoading: boolean = false;

	constructor(private cdr: ChangeDetectorRef, private progressEvent: ProgressEventService) {}

	ngAfterViewInit() {
		this.progressEvent.loader.subscribe((res: boolean) => {
			this.isLoading = res;
			this.cdr.detectChanges();
		});
	}
}
