import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Api } from 'src/app/const/api';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
	public editor = ClassicEditor;

	aboutUsData: any = '';
	privacyPolicyData: any = '';
	termsOfServiceData: any = '';

	constructor(private http: HttpService, private utility: UtilityService) {}

	ngOnInit(): void {
		this.getAboutUs();
		this.getPrivacyPolicy();
		this.getTermsOfService();
	}

	getAboutUs() {
		this.http.get(Api.ABOUT_US).subscribe({
			next: (res: any) => {
				this.aboutUsData = res;
			},
			error: (err: any) => {
				console.log(err);
			},
		});
	}

	saveAboutUs(aboutUsId: string) {
		this.http
			.patch(Api.UPDATE_ABOUT_US + aboutUsId, { text: this.aboutUsData.text })
			.subscribe({
				next: (res: any) => {
					this.utility.showSuccessToast('About us text changed.');
				},
				error: (err: any) => {
					this.utility.showSuccessToast('Unable to change About us text.');
				},
			});
	}

	getPrivacyPolicy() {
		this.http.get(Api.PRIVACY_POLICY).subscribe({
			next: (res: any) => {
				this.privacyPolicyData = res;
			},
			error: (err: any) => {
				console.log(err);
			},
		});
	}

	savePrivacyPolicy(privacyPolicyId: string) {
		this.http
			.patch(Api.UPDATE_PRIVACY_POLICY + privacyPolicyId, {
				text: this.privacyPolicyData.text,
			})
			.subscribe({
				next: (res: any) => {
					this.utility.showSuccessToast('Privacy policy changed.');
				},
				error: (err: any) => {
					this.utility.showSuccessToast('Unable to change Privacy policy.');
				},
			});
	}

	getTermsOfService() {
		this.http.get(Api.TERMS_OF_SERVICE).subscribe({
			next: (res: any) => {
				this.termsOfServiceData = res;
			},
			error: (err: any) => {
				console.log(err);
			},
		});
	}

	saveTermsOfService(termsOfServiceId: string) {
		this.http
			.patch(Api.UPDATE_TERMS_OF_SERVICE + termsOfServiceId, {
				text: this.termsOfServiceData.text,
			})
			.subscribe({
				next: (res: any) => {
					this.utility.showSuccessToast('Terms of service changed.');
				},
				error: (err: any) => {
					this.utility.showSuccessToast('Unable to change Terms of service.');
				},
			});
	}
}
