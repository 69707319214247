export const productGrowType = [
	{
		value: 'Indoor & Outdoor',
		title: 'Indoor & Outdoor',
	},
	{
		value: 'Indoor',
		title: 'Indoor',
	},
	{
		value: 'Outdoor',
		title: 'Outdoor',
	},
];

export const productFlowering = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: '< 6 weeks',
		title: '< 6 weeks',
	},
	{
		value: '7-8 weeks',
		title: '7-8 weeks',
	},
	{
		value: '8-9 weeks',
		title: '8-9 weeks',
	},
	{
		value: '9-10 weeks',
		title: '9-10 weeks',
	},
];

export const productClimate = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: 'Cold',
		title: 'Cold',
	},
	{
		value: 'Mild',
		title: 'Mild',
	},
	{
		value: 'Warm',
		title: 'Warm',
	},
	{
		value: 'Dry Temperate',
		title: 'Dry Temperate',
	},
];

export const landrace = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: 'Landrace',
		title: 'Landrace',
	},
	{
		value: 'Heirloom',
		title: 'Heirloom',
	},
	{
		value: 'Hybrid',
		title: 'Hybrid',
	},
	{
		value: 'Polyhybrid',
		title: 'Polyhybrid',
	},
];

export const domesticated = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: 'Equatorial',
		title: 'Equatorial',
	},
	{
		value: 'Tropical',
		title: 'Tropical',
	},
	{
		value: 'Temperate',
		title: 'Temperate',
	},
	{
		value: 'Cold Climate',
		title: 'Cold Climate',
	},
];

export const tissueCultures = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: 'Fertile',
		title: 'Fertile',
	},
	{
		value: 'Infertile',
		title: 'Infertile',
	},
	{
		value: 'Meristem',
		title: 'Meristem',
	},
	{
		value: 'Nodal',
		title: 'Nodal',
	},
	{
		value: 'Somatic Embryos',
		title: 'Somatic Embryos',
	},
];

export const productChemovars = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: 'Type I',
		title: 'Type I',
	},
	{
		value: 'Type II',
		title: 'Type II',
	},
	{
		value: 'Type III',
		title: 'Type III',
	},
	{
		value: 'Type IV',
		title: 'Type IV',
	},
];

export const productThc = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: '> 21%',
		title: '> 21%',
	},
	{
		value: '16-20%',
		title: '16-20%',
	},
	{
		value: '< 15%',
		title: '< 15%',
	},
];

export const productThca = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: '> 21%',
		title: '> 21%',
	},
	{
		value: '16-20%',
		title: '16-20%',
	},
	{
		value: '< 15%',
		title: '< 15%',
	},
];

export const productCbd = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: 'High',
		title: 'High',
	},
	{
		value: 'Medium',
		title: 'Medium',
	},
	{
		value: 'Low',
		title: 'Low',
	},
];

export const productCbda = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: 'High',
		title: 'High',
	},
	{
		value: 'Medium',
		title: 'Medium',
	},
	{
		value: 'Low',
		title: 'Low',
	},
];

export const productCbg = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: 'High',
		title: 'High',
	},
	{
		value: 'Medium',
		title: 'Medium',
	},
	{
		value: 'Low',
		title: 'Low',
	},
];

export const productCbga = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: 'High',
		title: 'High',
	},
	{
		value: 'Medium',
		title: 'Medium',
	},
	{
		value: 'Low',
		title: 'Low',
	},
];

export const productCbc = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: 'High',
		title: 'High',
	},
	{
		value: 'Medium',
		title: 'Medium',
	},
	{
		value: 'Low',
		title: 'Low',
	},
];

export const productTHCP = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: 'High',
		title: 'High',
	},
	{
		value: 'Medium',
		title: 'Medium',
	},
	{
		value: 'Low',
		title: 'Low',
	},
];

export const productTHCv = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: 'High',
		title: 'High',
	},
	{
		value: 'Medium',
		title: 'Medium',
	},
	{
		value: 'Low',
		title: 'Low',
	},
];

export const productCBDv = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: 'High',
		title: 'High',
	},
	{
		value: 'Medium',
		title: 'Medium',
	},
	{
		value: 'Low',
		title: 'Low',
	},
];

export const productCBN = [
	{
		value: 'Any',
		title: 'Any',
	},
	{
		value: 'High',
		title: 'High',
	},
	{
		value: 'Medium',
		title: 'Medium',
	},
	{
		value: 'Low',
		title: 'Low',
	},
];
