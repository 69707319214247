import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class HttpService {
	private baseUrl: string = environment.baseUrl;

	constructor(public http: HttpClient) {}

	get(endpoint: string) {
		return this.http.get(
			this.baseUrl + endpoint,
			//  { observe: 'response' }
		);
	}

	post(endpoint: string, body: any) {
		return this.http.post(
			this.baseUrl + endpoint,
			body,
			// { observe: 'body' observe: 'response'}
		);
	}

	put(endpoint: string, body: any) {
		return this.http.put(this.baseUrl + endpoint, body, { observe: 'response' });
	}

	delete(endpoint: string) {
		return this.http.delete(this.baseUrl + endpoint, { observe: 'response' });
	}

	patch(endpoint: string, body: any) {
		return this.http.patch(this.baseUrl + endpoint, body, { observe: 'response' });
	}
}
