import { Routes } from '@angular/router';
import { SettingsComponent } from './settings/settings.component';
import { WebsiteSettingsComponent } from './website-settings/website-settings.component';
import { FaqComponent } from './faq/faq.component';

export const SettingRoutingModule: Routes = [
	{ path: 'settings', component: SettingsComponent },
	{ path: 'website-settings', component: WebsiteSettingsComponent },
	{ path: 'faq', component: FaqComponent },
];
