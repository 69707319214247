import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './sign-in/sign-in.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PageRoutingModule } from './page-routing.module';
import { RouterModule } from '@angular/router';
import { PageComponent } from './page.component';
import { HomeModule } from './home/home.module';
import { CategoryModule } from './category/category.module';
import { ConsumerModule } from './consumer/consumer.module';
import { ProductModule } from './product/product.module';
import { SettingsModule } from './settings/settings.module';
// import { BlogModule } from './blog/blog.module';
import { ComponentsModule } from '../components/components.module';
import { SubscribersModule } from './subscibers/subscribers.module';
import { SupplierModule } from './supplier/supplier.module';
import { ShippingModule } from './shipping/shipping.module';
import { BrandModule } from './brand/brand.module';
import { OrderModule } from './order/order.module';
import { SalesModule } from './sales/sales.module';
import { ForumModule } from './forum/forum.module';
import { InviteCodeModule } from './invite-code/invite-code.module';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';

@NgModule({
	declarations: [PageComponent, SignInComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(PageRoutingModule),
		ReactiveFormsModule,
		ComponentsModule,
		HomeModule,
		CategoryModule,
		BrandModule,
		ConsumerModule,
		ProductModule,
		SettingsModule,
		// BlogModule,
		SubscribersModule,
		SupplierModule,
		ShippingModule,
		OrderModule,
		SalesModule,
		ForumModule,
		InviteCodeModule,
		NgxCsvParserModule,
		DividerModule,
		InputTextModule,
		ButtonModule,
	],
})
export class PageModule { }
