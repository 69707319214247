import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddCategoryComponent } from './add-category/add-category.component';
import { CategoriesComponent } from './categories/categories.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { CategoryRoutingModule } from './category-routing.module';
import { ComponentsModule } from 'src/app/components/components.module';

import { SimpleTableComponent } from 'src/app/components/simple-table/simple-table.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { FieldWithLabelComponent } from 'src/app/components/field-with-label/field-with-label.component';
@NgModule({
	declarations: [AddCategoryComponent, CategoriesComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(CategoryRoutingModule),
		ComponentsModule,
		ReactiveFormsModule,
		SimpleTableComponent,
		ButtonModule,
		InputTextModule,
		InputTextareaModule,
		DropdownModule,
		PageHeaderComponent,
		FieldWithLabelComponent,
	],
	providers: [DialogService],
})
export class CategoryModule {}
