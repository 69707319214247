import { Component, Input, OnInit } from '@angular/core';

@Component({
	standalone: true,
	selector: 'app-statistics-card',
	templateUrl: './statistics-card.component.html',
	styleUrls: ['./statistics-card.component.scss'],
})
export class StatisticsCardComponent {
	@Input('title') title: string;
	@Input('value') value: string | number;
	@Input('icon') icon: string;
	@Input('color') color: string;
	@Input('background') background: string;

}
