import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageComponent } from './pages/page.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';

const routes: Routes = [
	{ path: '', redirectTo: '/sign-in', pathMatch: 'full' },
	{
		path: 'sign-in',
		component: SignInComponent,
	},
	{ path: 'reset-password', component: SignInComponent },
	// { path: 'page/:id', component: PageComponent },
	{
		path: '',
		component: PageComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./pages/page.module').then((m) => m.PageModule),
			},
		],
		canActivate: [AuthGuardService],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
