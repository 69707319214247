<div class="page">
	<app-page-header header="Suppliers">
		<input type="file" name="" class="opacity-0 hidden w-0 h-0" #csvInput (change)="onCsvFileSelect($event)"
			accept=".csv">
		<!-- <button pButton (click)="csvInput.click()">
			<span class="material-symbols-rounded"> upload_file </span>
			Import CSV
		</button> -->
		<button pButton matTooltip="Add new category" (click)="exportSuppliers()">
			<span class="material-symbols-rounded"> download </span>
			Export As CSV
		</button>
		<button pButton matTooltip="Send email to all subscribers" (click)="sendEmail()">
			<span class="material-symbols-rounded"> mail </span>
			Email to Suppliers
		</button>
		<button pButton matTooltip="Send email to individual Suppliers" (click)="sendIndividualEmail()">
			<span class="material-symbols-rounded"> forward_to_inbox </span>
			Send Email
		</button>
	</app-page-header>

	<div class="product-container">
		<!-- <app-simple-table [columns]="columns" [data]="suppliers" [paginator]="true" [showFilter]="false"
			[showActions]="false" [onRowClick]="viewSupplier">
		</app-simple-table> -->

		<p-table #table dataKey="id" [columns]="columns" [value]="suppliers" [tableStyle]="{'min-width': '60rem'}"
			[paginator]="true" [rows]="20" [rowsPerPageOptions]="[15, 20, 25, 50]" [selectionMode]="'single'"
			rowGroupMode="subheader" styleClass="p-datatable-striped p-datatable-gridlines text-md" sortMode="multiple">
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th *ngFor="let col of columns"
						[ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
						[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
						[pSortableColumn]="col.field" [pSortableColumnDisabled]="col.disableSort" [class]="col.class">
						<div class="flex items-center">
							{{ col.header }}
							<p-sortIcon [field]="col.field" *ngIf="!col.disableSort"></p-sortIcon>
						</div>
					</th>
				</tr>

				<tr *ngIf="showFilter">
					<th *ngFor="let col of columns"
						[ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
						[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}" [class]="col.class">
						<p-columnFilter type="text" [field]="col.field"
							*ngIf="col.field != 'Action' && col.field != 'Photo' && col.field != 'Image'"></p-columnFilter>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
				<tr class="text-[12px] md:text-[14px]" (click)="viewSupplier(rowData, rowIndex)">
					<ng-container *ngFor="let col of columns">
						<!-- <td class="flex gap-1 items-center" *ngIf="showActions && col.fieldType == fieldTypes.Action"
							[class]="col.class">
							<div class="flex gap-1">
								<button pButton pRipple type="button" pTooltip="Edit" icon="pi pi-pencil"
									(click)="$event.stopPropagation(); onEdit(rowData, rowIndex)"
									class="p-button-rounded p-button-text" *ngIf="showEditAction"></button>

								<button pButton pRipple type="button" pTooltip="Delete" icon="pi pi-trash"
									(click)="$event.stopPropagation(); onDelete(rowData, rowIndex)"
									class="p-button-rounded p-button-text"
									*ngIf="showDeleteAction || rowData.showDeleteAction"
									[disabled]="rowData.IsReadOnly"></button>

								<button pButton pRipple type="button" [pTooltip]="extraActionToolTip"
									[icon]="extraActionIcon"
									(click)="$event.stopPropagation(); onExtraAction(rowData, rowIndex)"
									class="p-button-rounded p-button-text" *ngIf="showExtraAction"></button>
							</div>
						</td> -->
						<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == fieldTypes.Text || col.fieldType == fieldTypes.Email"
							[class]="'cursor-pointer ' + col.class"
							[pTooltip]="col.showToolTip ? rowData[col.field]: ''" [autoHide]="false"
							tooltipPosition="bottom">
							{{ rowData[col.field] ? rowData[col.field] : '-'}}
						</td>
						<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							*ngIf="col.fieldType == fieldTypes.Category" [class]="'cursor-pointer text-left '"
							[pTooltip]="col.showToolTip ? rowData[col.field]: ''" [autoHide]="false"
							tooltipPosition="bottom">
							<span class="flex gap-1 items-center">
								<div class="h-[14px] w-[14px] bg-[#c4c4c4]"
									[style.background]="rowData[col.colorField]"></div>
								<span [class]="col.class">
									{{ rowData[col.field]}}
								</span>
							</span>
						</td>
						<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}" *ngIf="
							col.fieldType == fieldTypes.Telephone" [class]="'cursor-pointer ' + col.class"
							[pTooltip]="col.showToolTip ? rowData[col.field]: ''" [autoHide]="false"
							tooltipPosition="bottom">
							{{ rowData[col.field]}}
						</td>
						<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == fieldTypes.Date" [class]="'cursor-pointer'">
							{{ rowData[col.field] | date: ((col.dateFormat) ? col.dateFormat : 'MM/dd/yyyy')}}
						</td>
						<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == fieldTypes.Number" [class]="'cursor-pointer'">
							{{col?.prefix}}{{ (rowData[col.field] ? rowData[col.field]: 0) | number:
							'1.2-2'}}{{col?.suffix}}
						</td>
						<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == fieldTypes.Boolean" [class]="'cursor-pointer ' + col.class">
							{{ rowData[col.field] ? col.trueValue : col.falseValue}}
						</td>
						<td class="cursor-pointer text-blue-500"
							[ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == fieldTypes.LinkButton" [class]="'cursor-pointer '+ col.class">
							<span (click)="$event.stopPropagation(); resendVerification(rowData)"
								*ngIf="rowData.status == 'NV' ">
								{{col.header}}
							</span>
						</td>
						<td class="cursor-pointer"
							[ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == fieldTypes.Image" [class]="'cursor-pointer'">
							<img [src]="col.imageBaseUrl + rowData[col.field]" [alt]=""
								class="h-14 w-14 max-h-14 max-w-14 rounded-md my-2" />
						</td>
						<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == fieldTypes.DaysAgo" [class]="'cursor-pointer ' + col.class">
							{{(rowData[col.field]) ? (rowData[col.field] | daysAgo) : '-'}}
						</td>

						<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == 'status'" [class]="'cursor-pointer ' + col.class">
							<span class=" text-white px-4 py-1 rounded-md font-semibold text-xs" [ngClass]="{
								'onboarding': rowData.status == 'O',
								'not-active': rowData.status == 'NA',
								'active': rowData.status == 'A',
								'in-progress': rowData.status == 'V',
								'on-boarding': rowData.status == 'NV'
							}">
								{{ rowData.statusText }}
							</span>
						</td>

						<td class="cursor-pointer"
							[ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == fieldTypes.ImageGroup" [class]="'cursor-pointer '+ col.class">
							<div class="relative flex gap-1">
								<ng-container *ngFor="let img of rowData[col.field]">
									<img [src]="img[col.imageKey]" [alt]="img[col.imageAltKey]"
										class="h-5 w-5 flex-shrink-0 rounded-full"
										onError="this.src = '/assets/images/placeholder-image.webp'"
										[pTooltip]="img[col.imageAltKey] + ' liked this'" *ngIf="img[col.imageKey]">

									<img [src]="'https://ui-avatars.com/api/?background=random&name=' + img[col.imageAltKey]"
										[alt]="img[col.imageAltKey]" class="h-5 w-5 flex-shrink-0 rounded-full"
										*ngIf="!img[col.imageKey]" />
								</ng-container>
							</div>
						</td>
					</ng-container>
				</tr>
			</ng-template>
		</p-table>


		<!-- <table mat-table matSort fixedLayout="true">
			<ng-container matColumnDef="company_name">
				<mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>Company</mat-header-cell>
				<mat-cell mat-cell *matCellDef="let element" class="capitalize cursor-pointer"
					[routerLink]="'/supplier/' + element.id">
					{{ element.company_name }}
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="supplier_name">
				<mat-header-cell *matHeaderCellDef mat-sort-header>Supplier Name</mat-header-cell>
				<mat-cell mat-cell *matCellDef="let element" class="capitalize cursor-pointer"
					[routerLink]="'/supplier/' + element.id">
					{{ element.supplier_name }}
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="supplier_email">
				<mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
				<mat-cell mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
					<a href="mailto:{{ element.supplier_email }}" class="font-semibold">
						{{ element.supplier_email }}
					</a>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="supplier_phone">
				<mat-header-cell *matHeaderCellDef>Phone</mat-header-cell>
				<mat-cell mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
					<a href="tel:+{{ element.phone_country }}{{ element.supplier_phone }}" class="font-semibold">
						{{ element.supplier_phone }}
					</a>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="created_at">
				<mat-header-cell *matHeaderCellDef mat-sort-header>Joined On</mat-header-cell>
				<mat-cell mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
					{{ element.created_at | date : 'MMM, dd yyyy' }}
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="status">
				<mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
				<mat-cell mat-cell *matCellDef="let element">
					<span class=" text-white px-4 py-2 rounded-full font-semibold" [ngClass]="{
								'onboarding': element.status == 'O',
								'not-active': element.status == 'NA',
								'active': element.status == 'A',
								'in-progress': element.status == 'V',
								'on-boarding': element.status == 'NV'
							}">
						{{ element.statusText }}
					</span>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="action">
				<mat-header-cell *matHeaderCellDef>Active/ Inactive Supplier</mat-header-cell>
				<mat-cell mat-cell *matCellDef="let element; let i = index">
					<mat-slide-toggle [checked]="element.status == 'A' ? true : false"
						(change)="updateSupplierStatus($event, element.id)" (click)="$event.stopPropagation()"
						*ngIf="element.status != 'NV'">
					</mat-slide-toggle>
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="suppliersColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: suppliersColumns; let element"></mat-row>
		</table> -->
	</div>
</div>