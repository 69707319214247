import { Component, OnInit } from '@angular/core';
import { Api } from 'src/app/const/api';
import { CommonApiCallService } from 'src/app/services/common-api-call/common-api-call.service';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { AddCategoryComponent } from '../add-category/add-category.component';
import { DialogService } from 'primeng/dynamicdialog';
import { FieldsType } from 'src/app/enums/fields-type.enum';

@Component({
	selector: 'app-categories',
	templateUrl: './categories.component.html',
	styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
	categoriesColumns: string[] = ['#', 'category_name', 'category_image', 'edit', 'delete'];

	columns = [
		{
			field: 'category_name',
			header: 'Category Name',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		// {
		// 	field: 'category_image',
		// 	header: 'Category Image',
		// 	disableSort: true,
		// 	fieldType: FieldsType.Image,
		// },
		{
			field: 'Actions',
			header: 'Actions',
			disableSort: true,
			fieldType: FieldsType.Action,
		},
	];

	categories: any[] = [];

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private commonApiCall: CommonApiCallService,
		private dialogService: DialogService,
	) { }

	ngOnInit(): void {
		this.getCategories();
	}

	getCategories() {
		this.commonApiCall.getCategories().subscribe({
			next: (res: any) => {
				this.categories = res;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	deleteCategory = (rowData: any, index: number) => {
		this.http.delete(Api.DELETE_CATEGORIES + rowData.id).subscribe({
			next: (res: any) => {
				this.categories.splice(index, 1);
			},
			error: (err: any) => {
				this.utilityService.showSuccessToast('Failed to remove category.');
			},
			complete: () => {
				this.utilityService.showSuccessToast('Category Removed.');
			},
		});
	};

	openAddCategoryDialog() {
		const ref = this.dialogService.open(AddCategoryComponent, {
			width: '350px',
			header: 'Add new category',
			data: {
				type: 'ADD',
			},
		});

		ref.onClose.subscribe((result) => {
			this.getCategories();
		});
	}

	editCategory = (rowData: any, index: number) => {
		const ref = this.dialogService.open(AddCategoryComponent, {
			header: 'Edit category',
			width: '350px',
			data: {
				type: 'EDIT',
				data: rowData,
			},
		});

		ref.onClose.subscribe((result) => {
			this.getCategories();
		});
	};
}
