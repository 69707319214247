import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Api } from 'src/app/const/api';
import { DialogAction } from 'src/app/enums/dialog-action';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { ManageFaqComponent } from '../manage-faq/manage-faq.component';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
	faqs: any;
	constructor(
		public dialogService: DialogService,
		private http: HttpService,
		private utilityService: UtilityService,
	) {}

	ngOnInit(): void {
		this.getFaqs();
	}

	getFaqs() {
		this.utilityService.showLoading();
		this.http.get(Api.GET_FAQ).subscribe({
			next: (res) => {
				this.faqs = res;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	addFAQ() {
		const dialogRef = this.dialogService.open(ManageFaqComponent, {
			header: 'Add Faq',
			width: '550px',
			data: {
				type: DialogAction.ADD,
			},
		});

		dialogRef.onClose.subscribe((result) => {
			this.getFaqs();
		});
	}

	editFaq(faq: any) {
		const dialogRef = this.dialogService.open(ManageFaqComponent, {
			header: 'Edit Faq',
			width: '550px',
			data: {
				type: DialogAction.EDIT,
				faqData: faq,
			},
		});

		dialogRef.onClose.subscribe((result) => {
			this.getFaqs();
		});
	}

	deleteFaq(faqId: string, index: number) {
		this.http.delete(Api.DELETE_FAQ(faqId)).subscribe({
			next: (res) => {
				this.faqs.splice(index, 1);
				this.utilityService.showSuccessToast('FAQ removed');
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	editFaqCategoryName(faq) {
		faq.isEditing = true;
	}

	updateFaqCategoryName(faqData: any) {
		faqData.isEditing = false;
		const params = {
			faq_category_name: faqData.faq_category_name,
		};
		this.http.patch(Api.UPDATE_FAQ_CATEGORY(faqData.category), params).subscribe({
			next: (res: any) => {
				this.utilityService.showSuccessToast('FAQ Category Name Updated.');
			},
			error: (err: any) => {
				console.log(err);
				this.utilityService.showSuccessToast('Unable to update FAQ FAQ Category Name.');
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}
}
