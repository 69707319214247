import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from 'src/app/const/api';
import { AppConst } from 'src/app/enums/app-const';

@Injectable({
	providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
	constructor() {}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		let authReq: any;
		if (req.url.includes(Api.SIGN_IN)) {
			authReq = req.clone();
		} else {
			let token = sessionStorage.getItem(AppConst.ACCESS_TOKEN);
			if (token) {
				authReq = req.clone({
					headers: req.headers.set('AUTHORIZATION', `Bearer ${token}`),
				});
			} else {
				authReq = req.clone();
			}
		}
		return next.handle(authReq);
	}
}
