<div class="side-menu">
	<div class="branding">
		<img src="/assets/images/dpsl-logo.jpg" alt="Logo" />
	</div>

	<div class="menu-items">
		<h5 class="pt-3 pb-1 uppercase text-gray-600 font-semibold text-xs">
			Products
		</h5>

		<a class="menu-item" routerLink="/products" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded">spa</span>
			<span>Products</span>
		</a>

		<a class="menu-item" routerLink="/brands" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded">store</span>
			<span>Brands</span>
		</a>

		<a class="menu-item" routerLink="/categories" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded">receipt</span>
			<span>Categories</span>
		</a>

		<h5 class="pt-3 pb-1 uppercase text-gray-600 font-semibold text-xs">
			Contact Database
		</h5>

		<a class="menu-item" routerLink="/consumers" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded">person</span>
			<span>Consumers</span>
		</a>

		<a class="menu-item" routerLink="/supplier" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded"> local_shipping </span>
			<span>Suppliers</span>
		</a>

		<a class="menu-item" routerLink="/subscribers" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded">groups</span>
			<span>Subscribers</span>
		</a>

		<a class="menu-item" routerLink="/testers" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded">person_4</span>
			<span>Testers</span>
		</a>

		<h5 class="pt-3 pb-1 uppercase text-gray-600 font-semibold text-xs">Data & Reports</h5>

		<a class="menu-item" routerLink="/orders" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded"> shopping_basket </span>
			<span>Orders</span>
		</a>

		<a class="menu-item" routerLink="/sales" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded"> show_chart </span>
			<span>Sales</span>
		</a>

		<a class="menu-item" routerLink="/home" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded"> dashboard </span>
			<span>Dashboard</span>
		</a>

		<h5 class="pt-3 pb-1 uppercase text-gray-600 font-semibold text-xs">Forums</h5>

		<a class="menu-item" routerLink="/forum-category" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded">
				forum
			</span>
			<span>Forums</span>
		</a>

		<!-- <a class="menu-item" routerLink="/forum" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded">
				forum
			</span>
			<span>Forum</span>
		</a> -->

		<a class="menu-item" routerLink="/invite-code" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded">
				forum
			</span>
			<span>Invite Code</span>
		</a>

		<h5 class="pt-3 pb-1 uppercase text-gray-600 font-semibold text-xs">
			purchase & shipping
		</h5>

		<a class="menu-item" routerLink="/website-settings" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded">settings</span>
			<span>Min Order Value</span>
		</a>

		<a class="menu-item" routerLink="/shipping-charges" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded"> qr_code </span>
			<span>Shipping Charges</span>
		</a>

		<a class="menu-item" routerLink="/shipping-manager" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded"> trolley </span>
			<span>Shipping Email</span>
		</a>

		<h5 class="pt-3 pb-1 uppercase text-gray-600 font-semibold text-xs">Admin</h5>

		<a class="menu-item" routerLink="/settings" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded">settings</span>
			<span>Settings</span>
		</a>

		<!-- <a class="menu-item" routerLink="/blogs" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded">edit_note</span>
			<span>Blog</span>
		</a> -->

		<a class="menu-item" routerLink="/faq" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded">
				question_exchange
			</span>
			<span>FAQ</span>
		</a>

		<button class="menu-item" (click)="signOut()">
			<span class="material-symbols-rounded">power_settings_new</span>
			<span>Sign Out</span>
		</button>
	</div>
</div>