<div class="page">
	<app-page-header header="Manage FAQs">
		<button pButton (click)="addFAQ()">
			<span class="material-symbols-rounded"> add </span>
			Add FAQ
		</button>
	</app-page-header>

	<div class="mt-4">
		<div class="flex flex-col gap-4 mb-4 p-4 border border-gray-200 rounded-lg"
			*ngFor="let faq of faqs; let i = index">
			<div class="w-[200px] max-w-[200px]">
				<span class="flex gap-2 items-center" *ngIf="faq.isEditing">
					<input type="text" class="p-2 border border-gray-300 rounded-lg w-full"
						[(ngModel)]="faq.faq_category_name">
					<span class="material-symbols-rounded cursor-pointer" (click)="updateFaqCategoryName(faq)">
						Save
					</span>
				</span>
				<span class="flex gap-2 items-center w-full" *ngIf="!faq.isEditing">
					{{faq.faq_category_name}}
					<span class="material-symbols-rounded cursor-pointer" (click)="editFaqCategoryName(faq)">
						edit
					</span>
				</span>
			</div>
			<div class="flex-auto">
				<p-accordion [activeIndex]="0">
					<p-accordionTab *ngFor="let faq of faq.faq; let i = index">
						<ng-template pTemplate="header">
							<span class="flex justify-between items-center gap-2 w-full">
								{{faq.title}}
								<div class="justify-end gap-2">
									<span class="material-symbols-rounded p-2"
										(click)="$event.preventDefault; $event.stopPropagation; editFaq(faq)">
										edit
									</span>
									<span class="material-symbols-rounded p-2"
										(click)="$event.preventDefault; $event.stopPropagation; deleteFaq(faq.id, i)">
										delete
									</span>
								</div>
							</span>
						</ng-template>
						<p [innerHTML]="faq.description"></p>
					</p-accordionTab>
				</p-accordion>
			</div>
		</div>
	</div>
</div>