import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShippingManagerComponent } from './shipping-manager/shipping-manager.component';
import { ShippingRoutingModule } from './shipping-routing.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { ShippingChargesComponent } from './shipping-charges/shipping-charges.component';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { FieldWithLabelComponent } from 'src/app/components/field-with-label/field-with-label.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
	declarations: [ShippingManagerComponent, ShippingChargesComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(ShippingRoutingModule),
		ComponentsModule,
		ReactiveFormsModule,
		PageHeaderComponent,
		FieldWithLabelComponent,
		ButtonModule,
		InputTextModule,
	],
})
export class ShippingModule {}
