<div class="flex flex-col gap-1 w-full">
	<div class="flex flex-col gap-1">
		<div class="flex gap-[2px]">
			<label [for]="label">{{title}}</label>
			@if(required){
			<label class="text-red-600 font-semibold">*</label>
			}
		</div>
		@if(description){
		<p class="p-0 m-0 text-[10px] text-[rgba(22,26,29,0.6)]">{{description}}</p>
		}

	</div>
	<ng-content></ng-content>
</div>