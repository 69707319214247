import { Component, OnInit } from '@angular/core';
import { Api } from 'src/app/const/api';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import * as dayjs from 'dayjs';
import { ExportService } from 'src/app/services/export/export.service';
import { EmailDialogComponent } from 'src/app/components/email-dialog/email-dialog.component';
import { ApiMessages } from 'src/app/const/messages';
import { IndividualEmailDialogComponent } from 'src/app/components/individual-email-dialog/individual-email-dialog.component';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-subscribers',
	templateUrl: './subscribers.component.html',
	styleUrls: ['./subscribers.component.scss'],
})
export class SubscribersComponent implements OnInit {
	columns = [
		{
			field: 'email',
			header: 'Subscriber Email',
			disableSort: false,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
		},
		{
			field: 'created_at',
			header: 'Subscribed on',
			disableSort: false,
			fieldType: FieldsType.Date,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
		},
	];
	subscribers: any[] = [];

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private exportService: ExportService,
		private dialogService: DialogService,
	) {}

	ngOnInit(): void {
		this.getSubScribers();
	}

	getSubScribers() {
		this.utilityService.showLoading();
		this.http.get(Api.GET_SUBSCRIBERS).subscribe({
			next: (res: any) => {
				this.subscribers = res;
			},
			error: (err) => {
				this.utilityService.hideLoading();
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	exportCSV() {
		const csvColumns = ['#', 'Email', 'Joined On'];
		let csvData = [];
		let dataToExport = [];
		dataToExport = this.subscribers;
		let index = 0;
		for (const data of dataToExport) {
			index++;
			csvData.push({
				'#': index,
				Email: data.email,
				'Joined On': dayjs(data.created_at).format('MMMM DD, YYYY'),
			});
		}
		this.exportService.exportToCsv(
			csvData,
			`subscribers-${dayjs().format('DD-MM-YYYY-HH-mm')}`,
			csvColumns,
		);
	}

	sendEmail() {
		const dialogRef = this.dialogService.open(EmailDialogComponent, {
			header: 'Send Email',
			width: '50%',
		});

		dialogRef.onClose.subscribe((params: any) => {
			this.http.post(Api.SEND_EMAIL_TO_SUBSCRIBERS, params).subscribe({
				next: (res: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(res.data);
				},
				error: (err: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(ApiMessages.SUBSCRIBER_EMAIL_SENT_FAIL);
				},
				complete: () => {},
			});
		});
	}

	sendIndividualEmail() {
		const emails = this.subscribers.map((data: any) => data.email);
		const dialogRef = this.dialogService.open(IndividualEmailDialogComponent, {
			header: 'Send Email',
			width: '50%',
			data: {
				emails: emails,
			},
		});

		dialogRef.onClose.subscribe((params: any) => {
			this.http.post(Api.SEND_INDIVIDUAL_EMAIL, params).subscribe({
				next: (res: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(res.data);
				},
				error: (err: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(ApiMessages.USERS_EMAIL_SENT_FAIL);
				},
				complete: () => {},
			});
		});
	}
}
