import { Injectable } from '@angular/core';
import { ConfirmEventType, ConfirmationService } from 'primeng/api';

@Injectable({
	providedIn: 'root',
})
export class ConfirmationDialogService {
	constructor(private confirmationService: ConfirmationService) {}

	/**
	 * Show Confirmation dialog
	 * @param header Header Text
	 * @param message description message
	 * @param onSuccess Callback on Success
	 * @param onReject Callback on Reject
	 * @param onCancel Callback on cancel
	 */

	showConfirmationDialog(
		header: string,
		message: string,
		onSuccess: Function,
		onReject: Function,
		onCancel: Function,
	) {
		this.confirmationService.confirm({
			header: header,
			message: message,
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				onSuccess();
			},
			reject: (type: ConfirmEventType) => {
				switch (type) {
					case ConfirmEventType.REJECT:
						onReject();
						break;
					case ConfirmEventType.CANCEL:
						onCancel();
						break;
				}
			},
		});
	}
}
