import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Api } from 'src/app/const/api';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
	selector: 'app-consumer-detail',
	templateUrl: './consumer-detail.component.html',
	styleUrls: ['./consumer-detail.component.scss'],
})
export class ConsumerDetailComponent implements OnInit {
	userId: string = '';
	userData: any;
	orderColumns = [
		{
			field: 'order_display_id',
			header: 'Order Id',
			disableSort: false,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
		},
		{
			field: 'name',
			header: 'Customer Name',
			disableSort: false,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
		},
		{
			field: 'created_at',
			header: 'Order Date',
			disableSort: false,
			fieldType: FieldsType.Date,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
		},
		{
			field: 'total',
			header: 'Order Total',
			disableSort: false,
			fieldType: FieldsType.Number,
			imageBaseUrl: '',
			prefix: '$',
			suffix: '',
			isEditable: false,
		},
	];
	orders: any[] = [];

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private route: ActivatedRoute,
	) {
		this.route.params.subscribe((params) => {
			this.userId = params['userId'];
			this.getConsumerDetails();
		});
	}

	ngOnInit(): void {}

	getConsumerDetails() {
		this.http.get(Api.GET_CONSUMERS_DETAILS(this.userId)).subscribe({
			next: (res: any) => {
				this.userData = res;
				this.orders = res.orders;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	orderDetails = () => {
		// [routerLink]="['/order-details', element.order_id]" style="cursor: pointer"
	};
}
