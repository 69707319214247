import { Component, OnInit } from '@angular/core';
import { Api } from 'src/app/const/api';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { AddForumCategoryComponent } from '../add-forum-category/add-forum-category.component';
import { TopicModeratorComponent } from '../topic-moderator/topic-moderator.component';
import { DialogService } from 'primeng/dynamicdialog';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { TableColumn } from 'src/app/interfaces/table-column';

@Component({
	selector: 'app-forums',
	templateUrl: './forums.component.html',
	styleUrls: ['./forums.component.scss'],
})
export class ForumsComponent implements OnInit {
	columns: TableColumn[] = [
		{
			field: 'topic_title',
			header: 'Topic',
			disableSort: false,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: true,
			class: 'w-[180px] max-w-[180px] md:max-w-[240px] md:w-240px overflow-hidden whitespace-nowrap text-ellipsis',
		},
		{
			field: 'forum_category_name',
			header: 'Gen Forums',
			disableSort: true,
			fieldType: FieldsType.Category,
			colorField: 'forum_category_color',
			class: 'w-[160px] max-w-[160px] overflow-hidden whitespace-nowrap text-ellipsis',
		},
		{
			field: 'views',
			header: 'Views',
			disableSort: true,
			fieldType: FieldsType.Text,
			width: '100px',
		},
		{
			field: 'replies',
			header: 'Replies',
			disableSort: false,
			fieldType: FieldsType.Text,
			width: '90px',
		},
		{
			field: 'likes',
			header: 'UPV/LIKES',
			disableSort: false,
			fieldType: FieldsType.Text,
			width: '100px',
		},
		{
			field: 'last_comment',
			header: 'Activity',
			disableSort: false,
			fieldType: FieldsType.DaysAgo,
			width: '160px',
		},
		{
			field: 'created_at',
			header: 'Created',
			disableSort: false,
			fieldType: FieldsType.DaysAgo,
			width: '160px',
		},
	];
	forums: any[] = [];

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private dialogService: DialogService,
	) {}

	ngOnInit(): void {
		this.getForums();
	}

	getForums() {
		this.http.get(Api.FORUM).subscribe({
			next: (res: any) => {
				this.forums = res;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	deleteCategory(categoryId: string, index: number) {
		this.http.delete(Api.DELETE_FORUM_CATEGORY + categoryId).subscribe({
			next: (res: any) => {
				this.forums.splice(index, 1);
			},
			error: (err: any) => {
				this.utilityService.showSuccessToast('Failed to remove category.');
			},
			complete: () => {
				this.utilityService.showSuccessToast('Category Removed.');
			},
		});
	}

	// manageTopicModerator(topicData: any, index: number) {
	// 	this.dialog.open(TopicModeratorComponent, {
	// 		width: '50%',
	// 		autoFocus: false,
	// 		data: {
	// 			topicId: topicData.id,
	// 			topicTitle: topicData.topic_title,
	// 		},
	// 	});

	// 	// dialogRef.afterClosed().subscribe((result) => {});
	// }

	// editCategory(categoryData, index) {
	// 	const dialogRef = this.dialogService.open(AddForumCategoryComponent, {
	// 		header: "Edit Category",
	// 		width: '350px',
	// 		data: {
	// 			type: 'EDIT',
	// 			data: categoryData,
	// 		},
	// 	});

	// 	dialogRef.afterClosed().subscribe((result) => {
	// 		this.getForums();
	// 	});
	// }
}
