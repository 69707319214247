import { enableProdMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageModule } from './pages/page.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { ComponentsModule } from './components/components.module';
import { AuthInterceptor } from './interceptor/auth-interceptor/auth-interceptor';
import { environment } from 'src/environments/environment';
import { LoaderComponent } from './components/loader/loader.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';

if (environment.production) {
	enableProdMode();
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		PageModule,
		HttpClientModule,
		ComponentsModule,
		ToastModule,
		LoaderComponent,
		ConfirmDialogModule,
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		MessageService,
		ConfirmationService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
