import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Api } from 'src/app/const/api';
import { ApiMessages } from 'src/app/const/messages';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
	selector: 'app-shipping-manager',
	templateUrl: './shipping-manager.component.html',
	styleUrls: ['./shipping-manager.component.scss'],
})
export class ShippingManagerComponent implements OnInit {
	shippingFrom: UntypedFormGroup;

	constructor(private utilityService: UtilityService, private http: HttpService) {
		this.shippingFrom = new UntypedFormGroup({
			shipping_manager_email: new UntypedFormControl('', [
				Validators.required,
				Validators.email,
			]),
		});
	}

	get shippingManagerEmail() {
		return this.shippingFrom.get('shipping_manager_email');
	}

	ngOnInit(): void {
		this.getShippingManagerEmail();
	}

	getShippingManagerEmail() {
		this.utilityService.showLoading();
		this.http.get(Api.GET_SHIPPING_MANAGER_EMAIL).subscribe({
			next: (res: any) => {
				this.utilityService.hideLoading();
				this.shippingFrom.patchValue({
					shipping_manager_email: res[0].setting_value,
				});
			},
			error: (err: any) => {
				this.utilityService.hideLoading();
			},
			complete: () => {},
		});
	}

	saveShippingManagerEmail() {
		if (this.shippingFrom.valid) {
			this.utilityService.showLoading();
			this.http.post(Api.SAVE_SHIPPING_MANAGER_EMAIL, this.shippingFrom.value).subscribe({
				next: (res: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(ApiMessages.SHIPPING_MANAGER_EMAIL_SAVED);
				},
				error: (err: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(
						ApiMessages.SHIPPING_MANAGER_EMAIL_SAVE_FAIL,
					);
				},
				complete: () => {},
			});
		} else {
			this.utilityService.showSuccessToast('Invalid Details.');
		}
	}
}
