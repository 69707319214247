import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { BrandRoutingModule } from './brand-routing.module';
import { ComponentsModule } from 'src/app/components/components.module';

import { AddBrandComponent } from './add-brand/add-brand.component';
import { BrandsComponent } from './brands/brands.component';
import { SimpleTableComponent } from 'src/app/components/simple-table/simple-table.component';
import { ButtonModule } from 'primeng/button';
import { FieldWithLabelComponent } from 'src/app/components/field-with-label/field-with-label.component';
import { InputTextModule } from 'primeng/inputtext';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
	declarations: [AddBrandComponent, BrandsComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(BrandRoutingModule),
		ComponentsModule,
		ReactiveFormsModule,
		SimpleTableComponent,
		ButtonModule,
		FieldWithLabelComponent,
		InputTextModule,
		InputTextareaModule,
		DynamicDialogModule,
		DropdownModule,
		PageHeaderComponent,
	],
})
export class BrandModule {}
