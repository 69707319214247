export class Api {
	static SIGN_IN = '/admin/sign-in';
	static GET_DETAILS = '/admin/get-details';

	static BRANDS = '/brand/';
	static ADD_BRAND = '/brand/add';
	static UPDATE_BRAND = (id: string) => `/brand/${id}`;

	static GET_CATEGORIES = '/categories/';
	static ADD_CATEGORIES = '/categories/add';
	static UPDATE_CATEGORY = (id: string) => `/categories/${id}`;
	static DELETE_CATEGORIES = '/categories/';

	static GET_SUPPLIERS = '/supplier';
	static GET_SUPPLIER_DETAILS = (id: string) => `/admin/supplier-details/${id}`;
	static GET_SUPPLIERS_PRODUCT = (id: string) => `/supplier/products/${id}`;
	static UPDATE_SUPPLIER_STATUS = (id: string) => `/supplier/${id}`;
	static IMPORT_SUPPLIERS = `/admin/import-suppliers`;

	static ADD_PRODUCT = '/product/add-product';
	static PRODUCTS = '/product/';
	static GET_FILTER_PRODUCTS = '/product/filter';
	static UPDATE_PRODUCT_STATUS = `/product/update-status`;
	static DELETE_PRODUCT = '/product/';
	static DELETE_PRODUCT_VARIANT = '/product/remove-product-variant/';
	static DELETE_PRODUCT_IMAGE = (id: string) => `/product/remove-product-image/${id}`;

	static GET_CONSUMERS = '/admin/all-users';
	static GET_CONSUMERS_DETAILS = (id: string) => `/admin/user-details/${id}`;
	static GET_TESTERS = '/admin/all-testers';
	static UPDATE_TESTER_STATUS = `/admin/tester-status`;
	static IMPORT_TESTERS = `/admin/import-testers`;

	static ABOUT_US = '/about-us/';
	static UPDATE_ABOUT_US = '/about-us/';

	static TERMS_OF_SERVICE = '/terms-of-service/';
	static UPDATE_TERMS_OF_SERVICE = '/terms-of-service/';

	static PRIVACY_POLICY = '/privacy-policy/';
	static UPDATE_PRIVACY_POLICY = '/privacy-policy/';

	static GET_BLOGS = '/blog/blogs';
	static ADD_BLOG = '/blog/add-blog';
	static GET_BLOG_DETAILS = (id: string) => `/blog/${id}`;
	static UPDATE_BLOG_DETAILS = (id: string) => `/blog/${id}`;
	static DELETE_BLOG = '/blog/';

	static GET_STATISTICS = '/admin/statistics';

	static GET_SUBSCRIBERS = '/admin/subscribers';
	static SEND_EMAIL_TO_SUBSCRIBERS = '/admin/send-email-to-subscribers';

	static GET_SHIPPING_MANAGER_EMAIL = '/admin/get-shipping-email';
	static SAVE_SHIPPING_MANAGER_EMAIL = '/admin/save-shipping-email';

	static GET_SHIPPING_CHARGES = '/admin/get-shipping-charges';
	static SAVE_SHIPPING_CHARGES = '/admin/save-shipping-charges';
	static REMOVE_SHIPPING_CHARGE = (id: any) => `/admin/remove-shipping-charges/${id}`;

	static GET_FILTER_ORDERS = '/orders/filter';
	static GET_ALL_ORDERS = '/orders/all-orders';
	static GET_ORDER_DETAILS = (id: string) => `/orders/details/${id}`;

	static GET_ALL_SALES = '/orders/sales';
	static GET_FILTER_SALES = '/orders/filter-sales';

	static GET_WEB_CONFIGS = '/configs';
	static SAVE_WEB_CONFIGS = '/save-configs';

	static FAQ_CATEGORIES = '/faq-categories';
	static ADD_FAQ = '/faq';
	static GET_FAQ = '/faq';
	static UPDATE_FAQ = (faqId: string) => `/faq/${faqId}`;
	static DELETE_FAQ = (faqId: string) => `/faq/${faqId}`;
	static UPDATE_FAQ_CATEGORY = (faqCategoryId: string) => `/update-faq-category/${faqCategoryId}`;

	static FORUM_CATEGORIES = '/admin/forum-categories';
	static ADD_FORUM_CATEGORY = '/admin/add-forum-categories';
	static UPDATE_FORUM_CATEGORY = (categoryId: string) =>
		`/admin/update-forum-categories/${categoryId}`;
	static DELETE_FORUM_CATEGORY = (categoryId: string) => `/admin/forum-categories/${categoryId}`;
	static FORUM = '/forum/topic';
	static FORUM_TOPIC_USER = (topicId: string) => `/forum/topic-users/${topicId}`;
	static FORUM_MODERATOR_FOR_CATEGORY = `/forum/forum-moderator-category`;
	static GET_FORUM_MODERATOR_FOR_CATEGORY = (categoryId: string) =>
		`/forum/forum-moderator-category/${categoryId}`;
	static INVITE_CODE = '/admin/invite-code';

	static SEND_EMAIL_TO_USERS = '/admin/send-email-to-users';
	static SEND_EMAIL_TO_TESTERS = '/admin/send-email-to-testers';
	static SEND_EMAIL_TO_SUPPLIERS = '/admin/send-email-to-supplier';
	static RESEND_VERIFICATION_EMAIL_TO_SUPPLIERS = '/admin/resend-supplier-verification';
	static SEND_INDIVIDUAL_EMAIL = '/admin/send-individual-email';

	static GET_TAX_PERCENT = '/get-tax';
}
