import { Component, OnInit } from '@angular/core';
import { Api } from 'src/app/const/api';
import { ExportService } from 'src/app/services/export/export.service';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { ApiMessages } from 'src/app/const/messages';
import { EmailDialogComponent } from 'src/app/components/email-dialog/email-dialog.component';

import { IndividualEmailDialogComponent } from 'src/app/components/individual-email-dialog/individual-email-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { Router } from '@angular/router';

@Component({
	selector: 'app-consumers',
	templateUrl: './consumers.component.html',
	styleUrls: ['./consumers.component.scss'],
})
export class ConsumersComponent implements OnInit {
	columns = [
		{
			field: 'name',
			header: 'Name',
			disableSort: false,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
			width: '120px',
		},
		{
			field: 'email',
			header: 'Email',
			disableSort: false,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
		},
		{
			field: 'mobile',
			header: 'Mobile',
			disableSort: false,
			fieldType: FieldsType.Telephone,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
		},
		{
			field: 'created_at',
			header: 'Joined On',
			disableSort: false,
			fieldType: FieldsType.Date,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
			dateFormat: `MMM-dd-YYYY h:mm a`,
		},
		{
			field: 'email_verified',
			header: 'Status',
			disableSort: false,
			fieldType: FieldsType.Boolean,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
			trueValue: 'Verified',
			falseValue: 'Not Verified',
			width: '100px',
			hideFilter: true,
			checkValue: 1
		},
		{
			field: 'forum_access',
			header: 'Forum Access',
			disableSort: false,
			fieldType: 'forum_access',
		},
		{
			field: 'is_moderator',
			header: 'Moderator',
			disableSort: false,
			fieldType: FieldsType.Boolean,
			trueValue: 'Yes',
			falseValue: 'No',
			width: '120px',
			hideFilter: true,
		},
	];
	consumer: any[] = [];

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private exportService: ExportService,
		private dialogService: DialogService,
		private router: Router,
	) {
		dayjs.extend(timezone);
		dayjs.extend(utc);
	}

	get fieldTypes() {
		return FieldsType;
	}

	ngOnInit(): void {
		this.getConsumers();
	}

	getConsumers() {
		this.http.get(Api.GET_CONSUMERS).subscribe({
			next: (res: any) => {
				this.consumer = res;
			},
			error: (err: any) => {
				console.log(err);
			},
		});
	}

	exportCSV() {
		const csvColumns = [
			'#',
			'First Name',
			'Last Name',
			'Email',
			'Phone',
			'Joined On',
			'Time',
			'Status',
		];
		let csvData = [];
		let dataToExport = [];
		dataToExport = this.consumer;
		let index = 0;
		for (const data of dataToExport) {
			index++;
			csvData.push({
				'#': index,
				'First Name': `${data.first_name}`,
				'Last Name': `${data.last_name}`,
				Email: data.email,
				Phone: data.mobile,
				'Joined On': dayjs(data.created_at).format('MMMM DD, YYYY'),
				Time: dayjs(data.created_at).tz('EST').format('h:mm a'),
				Status: data.status,
			});
		}
		this.exportService.exportToCsv(
			csvData,
			`consumers-${dayjs().format('DD-MM-YYYY-HH-mm')}`,
			csvColumns,
		);
	}

	sendEmail() {
		const dialogRef = this.dialogService.open(EmailDialogComponent, {
			header: 'Send Email',
			width: '50%',
		});

		dialogRef.onClose.subscribe((params: any) => {
			this.http.post(Api.SEND_EMAIL_TO_USERS, params).subscribe({
				next: (res: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(res.data);
				},
				error: (err: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(ApiMessages.USERS_EMAIL_SENT_FAIL);
				},
				complete: () => { },
			});
		});
	}

	sendIndividualEmail() {
		const emails = this.consumer.map((data: any) => data.email);
		const dialogRef = this.dialogService.open(IndividualEmailDialogComponent, {
			header: 'Send Email',
			width: '50%',
			data: {
				emails: emails,
			},
		});

		dialogRef.onClose.subscribe((params: any) => {
			this.http.post(Api.SEND_INDIVIDUAL_EMAIL, params).subscribe({
				next: (res: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(res.data);
				},
				error: (err: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(ApiMessages.USERS_EMAIL_SENT_FAIL);
				},
				complete: () => { },
			});
		});
	}

	viewConsumer = (consumer: any) => {
		this.router.navigate(['/consumers/' + consumer.id]);
	};
}
