import { Component, OnInit, ViewChild } from '@angular/core';
import { Api } from 'src/app/const/api';
import { CommonApiCallService } from 'src/app/services/common-api-call/common-api-call.service';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import * as dayjs from 'dayjs';
import { ExportService } from 'src/app/services/export/export.service';
import { TableColumn } from 'src/app/interfaces/table-column';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { Router } from '@angular/router';
@Component({
	selector: 'app-sales',
	templateUrl: './sales.component.html',
	styleUrls: ['./sales.component.scss'],
})
export class SalesComponent implements OnInit {
	_filterParamsCore = {
		dates: '',
		category_id: '',
		brand_id: '',
		supplier_id: '',
	};

	filterParams = this._filterParamsCore;

	columns: TableColumn[] = [
		{
			field: 'created_at',
			header: 'Date & Time',
			disableSort: false,
			fieldType: FieldsType.Date,
			dateFormat: 'MMM, dd YYYY hh:mm',
		},
		{
			field: 'supplier_name',
			header: 'Supplier',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'brand_name',
			header: 'Brand',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'category_name',
			header: 'Category',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'product_sku',
			header: 'Product SKU',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'product_name',
			header: 'Product',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'product_variant',
			header: 'Seeds/pack',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'quantity',
			header: 'Quantity',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'price',
			header: 'Price',
			disableSort: false,
			fieldType: FieldsType.Number,
			prefix: '$',
			suffix: '',
		},
		{
			field: 'order_id',
			header: 'Order',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
	];
	sales: any[] = [];

	categories: any = [];
	brands: any = [];
	suppliers: any = [];

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private exportService: ExportService,
		private commonApiCall: CommonApiCallService,
		private router: Router,
	) { }

	ngOnInit(): void {
		this.getSales();
		this.getCategories();
		this.getBrands();
		this.getSuppliers();
	}

	getSales() {
		this.http.get(Api.GET_ALL_SALES).subscribe({
			next: (res: any) => {
				this.sales = res;
				// this.sales.sortingDataAccessor = (item, property) => {
				// 	switch (property) {
				// 		case 'created_at':
				// 			console.log(new Date(item.created_at));
				// 			return new Date(item.created_at);
				// 		default:
				// 			return item[property];
				// 	}
				// };
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	getCategories() {
		this.commonApiCall.getCategories().subscribe({
			next: (res) => {
				this.categories = res;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	getBrands() {
		this.http.get(Api.BRANDS).subscribe({
			next: (res) => {
				this.brands = res;
			},
			error: (err) => {
				console.log(err);
			},
		});
	}

	getSuppliers() {
		this.utilityService.showLoading();
		this.http.get(Api.GET_SUPPLIERS).subscribe({
			next: (res) => {
				this.suppliers = res;
			},
			error: (err) => {
				this.utilityService.hideLoading();
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	exportOrders() {
		const csvColumns = [
			'#',
			'Order Date',
			'Supplier',
			'Brand',
			'Category',
			'SKU',
			'Product',
			'Seeds Per Pack',
			'Quantity',
			'Price',
			'Order ID',
		];
		let csvData = [];
		let dataToExport = [];
		dataToExport = this.sales;
		let index = 0;
		for (const data of dataToExport) {
			index++;
			csvData.push({
				'#': index,
				'Order Date': dayjs(data.created_at).format('MMMM DD, YYYY hh:mm a'),
				Supplier: data.supplier_name,
				Brand: data.brand_name,
				Category: data.category_name,
				SKU: data.product_sku,
				Product: data.product_name,
				'Seeds Per Pack': data.variant_name,
				Quantity: data.quantity,
				Price:
					data.price > data.product_discount_price
						? data.product_discount_price.toFixed(2)
						: data.price.toFixed(2),
				'Order ID': data.display_id,
			});
		}
		this.exportService.exportToCsv(
			csvData,
			`Sales-${dayjs().format('DD-MM-YYYY')}`,
			csvColumns,
		);
	}

	filterSales() {
		const { dates, brand_id, supplier_id, category_id } = this.filterParams;
		const startDateParsed = dates[0] ? dayjs(dates[0]).format('YYYY-MM-DD') : '';
		const endDateParsed = dates[1] ? dayjs(dates[1]).format('YYYY-MM-DD') : '';

		this.http
			.post(Api.GET_FILTER_SALES, {
				brand_id: brand_id,
				supplier_id: supplier_id,
				category_id: category_id,
				start_date: startDateParsed,
				end_date: endDateParsed,
			})
			.subscribe({
				next: (res: any) => {
					this.sales = res;
				},
				error: (err) => {
					console.log(err);
				},
				complete: () => {
					this.utilityService.hideLoading();
				},
			});
	}

	clearFilters() {
		this.filterParams = this._filterParamsCore;
		this.getSales();
	}

	salesDetails = (salesData: any) => {
		this.router.navigate(['/sales-details', salesData.id]);
	};
}
