<div class="page">
	<app-page-header header="Website Settings"></app-page-header>
	<div class="flex flex-col gap-4 pt-4">
		<app-field-with-label title="Min. Order Value:" [required]="true">
			<span class="text-xs">Minimum Order value for website orders</span>
			<p-inputNumber inputId="integeronly" [(ngModel)]="settings.min_order_value"
				placeholder="Minimum Order value" [useGrouping]="false"> </p-inputNumber>
		</app-field-with-label>

		<app-field-with-label title="Product Tax Percent" [required]="true">
			<span class="text-xs">Product Tax to charge from consumer</span>
			<p-inputNumber [(ngModel)]="settings.product_tax_percent" placeholder="Tax" [useGrouping]="false"
				mode="decimal" [maxlength]="5" [minFractionDigits]="2" [maxFractionDigits]="2" [min]="0" [max]="100">
			</p-inputNumber>
		</app-field-with-label>

		<div class="flex mt-2 justify-end">
			<button pButton (click)="saveSettings()">
				Save
			</button>
		</div>
	</div>
</div>