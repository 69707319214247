import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribersComponent } from './subscribers/subscribers.component';
import { SubscribersRoutingModule } from './subscribers-routing.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SimpleTableComponent } from 'src/app/components/simple-table/simple-table.component';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { ButtonModule } from 'primeng/button';

@NgModule({
	declarations: [SubscribersComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(SubscribersRoutingModule),
		ReactiveFormsModule,
		PageHeaderComponent,
		SimpleTableComponent,
		ButtonModule,
	],
})
export class SubscribersModule {}
