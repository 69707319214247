import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsumersComponent } from './consumers/consumers.component';
import { RouterModule } from '@angular/router';
import { ConsumerRoutingModule } from './consumer-routing.module';
// import { ComponentsModule } from 'src/app/components/components.module';

/** Material Modules */
// import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
// import { MatIconModule } from '@angular/material/icon';
// import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
// import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { ConsumerDetailComponent } from './consumer-detail/consumer-detail.component';
import { TestersComponent } from './testers/testers.component';
import { TesterDetailsComponent } from './tester-details/tester-details.component';
// import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormsModule } from '@angular/forms';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { SimpleTableComponent } from 'src/app/components/simple-table/simple-table.component';
import { ButtonModule } from 'primeng/button';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';

@NgModule({
	declarations: [
		ConsumersComponent,
		ConsumerDetailComponent,
		TestersComponent,
		TesterDetailsComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule.forChild(ConsumerRoutingModule),
		PageHeaderComponent,
		SimpleTableComponent,
		ButtonModule,
		NgxCsvParserModule, DropdownModule, TableModule
	],
})
export class ConsumerModule { }
