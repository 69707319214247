import { Component, OnInit } from '@angular/core';
import { Api } from 'src/app/const/api';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { AddInviteCodeComponent } from '../add-invite-code/add-invite-code.component';
import { DialogService } from 'primeng/dynamicdialog';
import { FieldsType } from 'src/app/enums/fields-type.enum';

@Component({
	selector: 'app-invite-code',
	templateUrl: './invite-code.component.html',
	styleUrls: ['./invite-code.component.scss'],
})
export class InviteCodeComponent implements OnInit {
	columns = [
		// 'invite_code', 'expired_on', 'action'
		{
			field: 'invite_code',
			header: 'Invite Code',
			disableSort: false,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
		},
		{
			field: 'expired_on',
			header: 'Expired On',
			disableSort: false,
			fieldType: FieldsType.Date,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
		},
		{
			field: 'action',
			header: 'Action',
			disableSort: false,
			fieldType: FieldsType.Action,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
		},
	];
	inviteCodeList: any[] = [];
	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private dialogService: DialogService,
	) {}

	ngOnInit(): void {
		this.getInviteCodes();
	}

	getInviteCodes() {
		this.http.get(Api.INVITE_CODE).subscribe({
			next: (res: any) => {
				this.inviteCodeList = res;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	removeInviteCode(inviteCodeId: string, index: number) {
		this.http.delete(`${Api.INVITE_CODE}/${inviteCodeId}`).subscribe({
			next: (res: any) => {
				this.inviteCodeList.splice(index, 1);
			},
			error: (err: any) => {
				this.utilityService.showSuccessToast('Unable to remove Invite Code.');
			},
			complete: () => {
				this.utilityService.showSuccessToast('Invite Code Removed.');
			},
		});
	}

	createNewInviteCode() {
		const dialogRef = this.dialogService.open(AddInviteCodeComponent, {
			header: 'Create Invite Code',
			width: '350px',
			data: {
				type: 'ADD',
			},
		});
		dialogRef.onClose.subscribe((result) => {
			this.getInviteCodes();
		});
	}

	editInviteCode(inviteCodeData, index) {
		const dialogRef = this.dialogService.open(AddInviteCodeComponent, {
			header: 'Edit Invite Code',
			width: '350px',
			data: {
				type: 'EDIT',
				data: inviteCodeData,
			},
		});
		dialogRef.onClose.subscribe((result) => {
			this.getInviteCodes();
		});
	}
}
