<div class="page">
	<form [formGroup]="brandFrom" (ngSubmit)="submitCategoryData()">
		<div class="flex flex-col gap-2">
			<app-field-with-label title="Brand Name" [required]="true">
				<input type="text" pInputText formControlName="brand_name" [required]="true" />
				<span *ngIf="brand_name.invalid && (brand_name.dirty || brand_name?.touched)">
					<span *ngIf="brand_name?.errors?.['required']"> Brand name is required </span>
				</span>
			</app-field-with-label>

			<app-field-with-label title="Brand Description" [required]="true">
				<textarea rows="3" pInputTextarea formControlName="brand_description" required>
			</textarea>

				<span *ngIf="brand_description.invalid && (brand_description.dirty || brand_description?.touched)">
					<span *ngIf="brand_description?.errors?.['required']">
						Brand Description is required
					</span>
				</span>
			</app-field-with-label>

			<app-field-with-label title="Position" [required]="true">
				<p-dropdown formControlName="position" [options]="brandPosition" optionLabel="title" optionValue="value"
					placeholder="Select a Brand Position">
				</p-dropdown>

				<span *ngIf="position.invalid && (position.dirty || position?.touched)">
					<span *ngIf="position?.errors?.['required']"> Brand Position is required </span>
				</span>
			</app-field-with-label>
			<!-- <app-field-with-label>
			<p-dropdown formControlName="direction" [options]="brandDirection" optionLabel="title" optionValue="value"placeholder="Select a Brand Direction">
			</p-dropdown>
			<span *ngIf="direction.invalid && (direction.dirty || direction?.touched)">
				<span *ngIf="direction?.errors?.['required']"> Direction is required </span>
			</span>
		</app-field-with-label> -->

			<div>
				<div class="flex items-center gap-2">
					<span>Brand Logo:</span>
					<div>
						<img src="/assets/images/add.png" alt="Select Brand Logo(Visible on Website)"
							class="add-image h-[100px] w-[100px] mx-auto my-2" (click)="brandLogo.click()"
							matTooltip="Select Brand Logo(Visible on Website)" *ngIf="!brandLogoPreview" />
						<img [src]="brandLogoPreview" alt="Select Brand Logo(Visible on Website)"
							class="add-image h-[100px] w-auto mx-auto my-2" (click)="brandLogo.click()"
							matTooltip="Select Brand Logo(Visible on Website)" *ngIf="brandLogoPreview" />
						<input type="file" class="file-input" #brandLogo (change)="onBrandLogoSelect($event)"
							accept="image/png, image/jpeg" />
					</div>
				</div>

				<div class="flex items-center gap-2 w-full">
					<span>Brand Poster:</span>
					<div>
						<img src="/assets/images/add.png" alt="Select Brand Cover(Visible on website)"
							class="add-image h-[100px] w-full object-cover mx-auto my-2 opacity-20"
							(click)="brandCover.click()" matTooltip="Select Brand Cover(Visible on website)"
							*ngIf="!brandCoverPreview" />
						<img [src]="brandCoverPreview" alt="Select Brand Cover(Visible on website)"
							class="add-image h-[100px] w-auto mx-auto my-2" (click)="brandCover.click()"
							matTooltip="Select Brand Cover(Visible on website)" *ngIf="brandCoverPreview" />
						<input type="file" class="file-input" #brandCover (change)="onBrandCoverSelect($event)"
							accept="image/png, image/jpeg" value="Choose Cover(Visible on website)" />
					</div>
				</div>
			</div>
			<div class="flex justify-end">
				<button pButton type="submit">Save Brand</button>
			</div>
		</div>
	</form>
</div>