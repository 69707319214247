import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Api } from 'src/app/const/api';
import { DialogAction } from 'src/app/enums/dialog-action';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
	selector: 'app-add-invite-code',
	templateUrl: './add-invite-code.component.html',
	styleUrls: ['./add-invite-code.component.scss'],
})
export class AddInviteCodeComponent implements OnInit {
	dialogAction: DialogAction;
	from: FormGroup;
	inviteCodeData: any;

	constructor(
		private utility: UtilityService,
		private http: HttpService,
		public ref: DynamicDialogRef,
		public dialogConfig: DynamicDialogConfig,
	) {}

	get DialogAction() {
		return DialogAction;
	}

	ngOnInit(): void {
		this.from = new FormGroup({
			invite_code: new FormControl('', [Validators.required]),
			expired_on: new FormControl('', [Validators.required]),
		});
		const { type, data } = this.dialogConfig.data;

		this.dialogAction = type;

		if (this.dialogAction == DialogAction.EDIT) {
			this.inviteCodeData = data;
			this.from.patchValue({
				invite_code: this.inviteCodeData.invite_code,
				expired_on: new Date(this.inviteCodeData.expired_on),
			});
		}
	}

	get inviteCode() {
		return this.from.get('invite_code');
	}

	get expiredOn() {
		return this.from.get('expired_on');
	}

	get dialogActions() {
		return DialogAction;
	}

	submitData() {
		if (this.dialogAction == DialogAction.ADD) {
			this.addInviteCode();
		} else if (this.dialogAction == DialogAction.EDIT) {
			this.updateInviteCode();
		}
	}

	addInviteCode() {
		if (this.from.valid) {
			this.utility.showLoading();
			let params = {
				invite_code: this.from.value.invite_code,
				expired_on: this.utility.formatDate(
					this.from.value.expired_on,
					'YYYY-MM-DD HH:mm:ss',
				),
			};
			this.http.post(Api.INVITE_CODE, params).subscribe({
				next: (res: any) => {
					this.utility.showSuccessToast('Invite Code Added.');
					this.from.reset();
					this.closeDialog();
				},
				error: (err: any) => {
					console.log(err);
					this.utility.showSuccessToast('Unable to add Invite Code.');
				},
				complete: () => {
					this.utility.hideLoading();
				},
			});
		} else {
			this.utility.showSuccessToast('Required fields are missing.');
		}
	}

	updateInviteCode() {
		if (this.from.valid) {
			this.utility.showLoading();
			let params = {
				invite_code: this.from.value.invite_code,
				expired_on: this.utility.formatDate(
					this.from.value.expired_on,
					'YYYY-MM-DD HH:mm:ss',
				),
			};
			this.http.patch(`${Api.INVITE_CODE}/${this.inviteCodeData.id}`, params).subscribe({
				next: (res: any) => {
					this.utility.showSuccessToast('Invite Code Updated.');
					this.closeDialog();
				},
				error: (err: any) => {
					console.log(err);
					this.utility.showSuccessToast('Unable to update Invite Code.');
				},
				complete: () => {
					this.utility.hideLoading();
				},
			});
		} else {
			this.utility.showSuccessToast('Required fields are missing.');
		}
	}

	generateRandomInviteCode() {
		const randomCode = this.utility.generateRandomString();
		this.from.patchValue({
			invite_code: randomCode,
		});
	}

	closeDialog() {
		this.ref.close();
	}
}
