import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Api } from 'src/app/const/api';
import { DialogAction } from 'src/app/enums/dialog-action';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
	selector: 'app-add-forum-category',
	templateUrl: './add-forum-category.component.html',
	styleUrls: ['./add-forum-category.component.scss'],
})
export class AddForumCategoryComponent implements OnInit {
	dialogAction: DialogAction;
	categoryFrom: FormGroup;
	categoryData: any;

	hexRegex = /^#([A-F0-9]{3}|[A-F0-9]{6})$/i;

	constructor(
		private utility: UtilityService,
		private http: HttpService,
		private ref: DynamicDialogRef,
		private dialogConfig: DynamicDialogConfig,
	) {}

	ngOnInit(): void {
		this.categoryFrom = new FormGroup({
			forum_category_name: new FormControl('', [Validators.required]),
			forum_category_color: new FormControl('', [
				Validators.required,
				Validators.pattern(this.hexRegex),
			]),
		});
		const { type, data } = this.dialogConfig.data;
		this.dialogAction = type;

		if (this.dialogAction == DialogAction.EDIT) {
			this.categoryData = data;
			this.categoryFrom.patchValue({
				forum_category_name: this.categoryData.forum_category_name,
				forum_category_color: this.categoryData.forum_category_color,
			});
		}
	}

	get forum_category_name() {
		return this.categoryFrom.get('forum_category_name');
	}

	get forum_category_color() {
		return this.categoryFrom.get('forum_category_color');
	}

	get dialogActions() {
		return DialogAction;
	}

	submitCategoryData() {
		if (this.dialogAction == DialogAction.ADD) {
			this.addCategory();
		} else if (this.dialogAction == DialogAction.EDIT) {
			this.editCategory();
		}
	}

	addCategory() {
		if (this.categoryFrom.valid) {
			this.utility.showLoading();
			let params = {
				forum_category_name: this.categoryFrom.value.forum_category_name,
				forum_category_color: this.categoryFrom.value.forum_category_color,
			};
			this.http.post(Api.ADD_FORUM_CATEGORY, params).subscribe({
				next: (res: any) => {
					this.utility.showSuccessToast('Forum Category Added.');
					this.categoryFrom.reset();
				},
				error: (err: any) => {
					console.log(err);
					this.utility.showSuccessToast('Unable to add Forum category.');
				},
				complete: () => {
					this.utility.hideLoading();
				},
			});
		} else {
			this.utility.showSuccessToast('Please check your details.');
		}
	}

	editCategory() {
		if (this.categoryFrom.valid) {
			this.utility.showLoading();
			let params = {
				forum_category_name: this.categoryFrom.value.category_name,
				forum_category_color: this.categoryFrom.value.category_code,
			};
			this.http.patch(Api.UPDATE_FORUM_CATEGORY(this.categoryData.id), params).subscribe({
				next: (res: any) => {
					this.utility.showSuccessToast('Forum Category Updated.');
					this.closeDialog();
				},
				error: (err: any) => {
					console.log(err);
					this.utility.showSuccessToast('Unable to update Forum category.');
				},
				complete: () => {
					this.utility.hideLoading();
				},
			});
		} else {
			this.utility.showSuccessToast('Please check your details.');
		}
	}

	closeDialog() {
		this.ref.close();
	}
}
