<div class="page">
	<app-page-header header="Supplier Details | {{ supplierData?.supplier_name }}"></app-page-header>
	<p-tabView>
		<p-tabPanel header="Basic Details">
			<div class="add-product-container" *ngIf="supplierData">
				<h2 class="text-xl font-semibold pt-2">Company Details</h2>
				<div class="grid grid-cols-2 gap-3 border-b border-gray-300 py-2">
					<div>
						<div class="detail-fields">
							<p>Company name:</p>
							<p>{{ supplierData.company_name }}</p>
						</div>
					</div>
				</div>

				<div class="border-b border-gray-300 py-2">
					<div class="flex">
						<div class="w-full border-r border-gray-300 pr-12">
							<div class="flex items-center justify-between">
								<h2 class="text-xl font-semibold pt-2 pb-1">Supplier Details</h2>
							</div>
							<div class="detail-fields">
								<p>Supplier name:</p>
								<p>{{ supplierData.supplier_name }}</p>
							</div>

							<div class="detail-fields">
								<p>Supplier Email:</p>
								<p class="">
									<a href="mailto:{{ supplierData.supplier_email }}">
										{{ supplierData.supplier_email }}
									</a>
								</p>
							</div>

							<div class="detail-fields">
								<p>Supplier Phone:</p>
								<p class="">
									<a href="tel:{{ supplierData.phone_country }}{{
											supplierData.supplier_phone
										}}">{{ supplierData.supplier_phone }}</a>
								</p>
							</div>

							<div class="detail-fields">
								<p>Registered On:</p>
								<p>{{ supplierData.created_at | date : 'medium' }}</p>
							</div>
						</div>

						<div class="w-full pl-8">
							<div class="flex items-center justify-between">
								<h2 class="text-xl font-semibold pt-2 pb-1">Supplier Address</h2>
							</div>

							<div class="detail-fields">
								<p>Supplier Address 1:</p>
								<p>{{ supplierData.address_1 }}</p>
							</div>

							<div class="detail-fields">
								<p>Supplier Address-2:</p>
								<p>{{ supplierData.address_2 }}</p>
							</div>

							<div class="detail-fields">
								<p>Supplier City:</p>
								<p>{{ supplierData.city }}</p>
							</div>

							<div class="detail-fields">
								<p>Supplier State:</p>
								<p>{{ supplierData.state }}</p>
							</div>

							<div class="detail-fields">
								<p>Supplier Country:</p>
								<p>{{ supplierData.country }}</p>
							</div>

							<div class="detail-fields">
								<p>landmark:</p>
								<p>{{ supplierData.landmark }}</p>
							</div>
						</div>
					</div>
				</div>

				<div class="border-b border-gray-300 py-2">
					<div class="flex">
						<div class="w-full border-r border-gray-300 pr-12">
							<div class="flex items-center justify-between">
								<h2 class="text-xl font-semibold pt-2 pb-1">Supplier Products</h2>
							</div>

							<app-simple-table [columns]="columns" [data]="products" [paginator]="true"
											  [showFilter]="false" [showActions]="false" [onRowClick]="viewProductDetails">
							</app-simple-table>
						</div>
					</div>
				</div>
				<form [formGroup]="form" (ngSubmit)="saveSupplierDetails()">
					<div class="border-b border-gray-300 py-2 grid grid-cols-2">
						<div class="">
							<div class="flex items-center justify-between">
								<h2 class="text-xl font-semibold">Supplier Status</h2>
							</div>
							<div class="form-field border rounded-sm p-2">
								<div class="field-title-container">
									<span class="mt-0">Enable / Disable supplier in product section</span>
								</div>
								<div class="form-field-container">
									<!-- <mat-slide-toggle [(ngModel)]="supplierStatus" (change)="updateSupplierStatus($event)">
									</mat-slide-toggle> -->
									<p-dropdown formControlName="status" [options]="statuses" optionLabel="label"
												optionValue="id" placeholder="Select a Status"></p-dropdown>
								</div>
							</div>
						</div>
					</div>

					<div class="border-b border-gray-300 py-2">

						<!-- <div class="flex items-center justify-between">
							<h2 class="text-xl font-semibold">Supplier Details:</h2>
						</div> -->
						<div class="flex flex-col mt-4 gap-2">
							<div class="field-title-container">
								<p class="text-sm m-0 p-0">Supplier Description:</p>
								<span class="text-xs">Pls add 80-120 words that best describe your company, its ethos and products.</span>
							</div>
							<div class="form-field-container w-full">
								<!-- <mat-form-field appearance="outline">
									<textarea matInput formControlName="supplierDescription" rows="3"
										class="resize-none" style="height: 74px"
										placeholder="Add supplier description..."></textarea>
								</mat-form-field> -->
								<ckeditor [editor]="editor" [config]="editorConfig"
										  formControlName="supplierDescription" class="input-style w-full !p-0 h-full">
								</ckeditor>
							</div>
						</div>
						<div class="flex flex-col mt-4 gap-2">
							<div class="field-title-container">
								<p class="text-sm m-0 p-0">Supplier Poster:</p>
								<span class="text-xs">Square poster for search results summary. Size 1000 x 1000
									pixels.</span>
							</div>
							<div class="form-field-container">
								<div class="image-container">
									<div class="selected-image-container" *ngIf="supplierPosterPreview"
										 (click)="mainImage.click()">

										<p-button icon="pi pi-times" [rounded]="true" severity="danger"
												  (click)="removeSupplierLogo()" pTooltip="Remove selected image" size="small"
												  styleClass="remove-button">
										</p-button>

										<img [src]="supplierPosterPreview" alt="Supplier Poster" />
									</div>

									<img src="/assets/images/add.png" alt="Select poster for supplier page"
										 class="add-image" (click)="mainImage.click()"
										 matTooltip="Select poster for supplier page" *ngIf="!supplierPosterPreview" />

									<input type="file" class="file-input" #mainImage
										   (change)="onSupplierLogoSelect($event)" accept="image/png, image/jpeg" />
								</div>
							</div>
						</div>

						<div class="flex flex-col mt-4 gap-2">
							<div class="field-title-container">
								<p class="text-sm m-0 p-0">Supplier Banner:</p>
								<span class="text-xs">Rectangular banner required for shopfront size 1900 x 300
									pixels.</span>
							</div>
							<div class="form-field-container">
								<div class="image-container">
									<div class="selected-image-container" *ngIf="supplierBannerPreview">
										<p-button icon="pi pi-times" [rounded]="true" severity="danger"
												  (click)="removeSupplierCover()" pTooltip="Remove selected image"
												  size="small" styleClass="remove-button">
										</p-button>

										<img [src]="supplierBannerPreview"
											 alt="Select Banner image for supplier page" />
									</div>
									<img src="/assets/images/add.png" alt="Select banner image for supplier page"
										 class="add-image" (click)="bannerImage.click()"
										 matTooltip="Select banner image for supplier page"
										 *ngIf="!supplierBannerPreview" />
									<input type="file" class="file-input" #bannerImage
										   (change)="onSupplierCoverSelect($event)" accept="image/png, image/jpeg"
										   multiple />
								</div>
							</div>
						</div>
						<div class="flex mt-2 justify-end">
							<button pButton type="submit" pTooltip="Save Supplier details">
								Save
							</button>
						</div>
					</div>
				</form>
			</div>
		</p-tabPanel>
		<p-tabPanel label="Sales">Sales</p-tabPanel>
	</p-tabView>
</div>