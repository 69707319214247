import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Api } from 'src/app/const/api';
import { DialogAction } from 'src/app/enums/dialog-action';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-add-category',
	templateUrl: './add-brand.component.html',
	styleUrls: ['./add-brand.component.scss'],
})
export class AddBrandComponent implements OnInit {
	dialogAction: DialogAction;
	brandFrom: FormGroup;
	brandData: any;
	brandLogoPreview: any;
	brandCoverPreview: any;
	selectedBrandLogo: any;
	selectedBrandCover: any;

	readonly imageUrl: string = environment.imageUrl;
	brandPosition = [
		{
			title: 'Left',
			value: 'L',
		},
		{
			title: 'Right',
			value: 'R',
		},
	];
	brandDirection = [
		{
			title: 'Horizontal',
			value: 'H',
		},
		{
			title: 'Horizontal Reverse',
			value: 'HR',
		},
		{
			title: 'Vertical',
			value: 'V',
		},
		{
			title: 'Vertical Reverse',
			value: 'VR',
		},
	];
	constructor(
		private utility: UtilityService,
		private http: HttpService,
		private ref: DynamicDialogRef,
		private dialogConfig: DynamicDialogConfig,
	) {}

	ngOnInit(): void {
		this.brandFrom = new FormGroup({
			brand_name: new FormControl('', [Validators.required]),
			brand_description: new FormControl('', [Validators.required]),
			position: new FormControl('L', [Validators.required]),
			// direction: new UntypedFormControl('H', [Validators.required]),
		});
		const { type, data } = this.dialogConfig.data;
		this.dialogAction = type;

		if (this.dialogAction == DialogAction.EDIT) {
			this.brandData = data;
			this.brandFrom.patchValue({
				brand_name: this.brandData.brand_name,
				brand_description: this.brandData.brand_description,
				position: this.brandData.position,
			});
			this.brandCoverPreview = this.imageUrl + this.brandData.brand_cover;
			this.brandLogoPreview = this.imageUrl + this.brandData.brand_logo;
		}
	}

	get DialogActionType() {
		return DialogAction;
	}

	get brand_name() {
		return this.brandFrom.get('brand_name');
	}

	get brand_code() {
		return this.brandFrom.get('brand_code');
	}

	get brand_description() {
		return this.brandFrom.get('brand_description');
	}

	get position() {
		return this.brandFrom.get('position');
	}

	// get direction() {
	// 	return this.brandFrom.get('direction');
	// }

	submitCategoryData() {
		if (this.dialogAction == DialogAction.ADD) {
			this.addBrand();
		} else if (this.dialogAction == DialogAction.EDIT) {
			this.editBrand();
		}
	}

	addBrand() {
		if (this.brandFrom.valid) {
			this.utility.showLoading();
			const { brand_name, brand_description, position, direction } = this.brandFrom.value;

			const _formData: any = new FormData();
			if (this.selectedBrandCover) {
				_formData.append(
					'brand_cover',
					this.selectedBrandCover,
					this.selectedBrandCover.name,
				);
			}

			if (this.selectedBrandLogo) {
				_formData.append('brand_logo', this.selectedBrandLogo, this.selectedBrandLogo.name);
			}
			_formData.append('brand_name', brand_name);
			_formData.append('brand_description', brand_description);
			_formData.append('position', position);
			// _formData.append('direction', direction);

			this.http.post(Api.ADD_BRAND, _formData).subscribe({
				next: (res: any) => {
					this.utility.hideLoading();
					this.utility.showSuccessToast('Brand Added.');
					this.brandFrom.reset();
					this.selectedBrandCover = null;
					this.selectedBrandLogo = null;
				},
				error: (err: any) => {
					console.log(err);
					this.utility.hideLoading();
					this.utility.showSuccessToast('Unable to add Brand.');
				},
				complete: () => {
					this.utility.hideLoading();
				},
			});
		} else {
			this.utility.showSuccessToast('Please check your details.');
		}
	}

	editBrand() {
		if (this.brandFrom.valid) {
			this.utility.showLoading();
			const { brand_name, brand_description, position, direction } = this.brandFrom.value;

			const _formData: any = new FormData();
			if (this.selectedBrandCover) {
				_formData.append(
					'brand_cover',
					this.selectedBrandCover,
					this.selectedBrandCover.name,
				);
			}

			if (this.selectedBrandLogo) {
				_formData.append('brand_logo', this.selectedBrandLogo, this.selectedBrandLogo.name);
			}
			_formData.append('brand_name', brand_name);
			_formData.append('brand_description', brand_description);
			_formData.append('position', position);
			this.http.patch(Api.UPDATE_BRAND(this.brandData.id), _formData).subscribe({
				next: (res: any) => {
					this.utility.showSuccessToast('Brand Updated.');
					this.utility.hideLoading();
					this.closeDialog();
				},
				error: (err: any) => {
					this.utility.hideLoading();
					this.utility.showSuccessToast('Unable to update brand.');
				},
				complete: () => {
					this.utility.hideLoading();
				},
			});
		} else {
			this.utility.showSuccessToast('Please check your details.');
		}
	}

	closeDialog() {
		this.ref.close();
	}

	onBrandLogoSelect(event: any) {
		this.selectedBrandLogo = event.target.files[0];
		if (event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (_event) => {
				this.brandLogoPreview = reader.result;
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	}

	onBrandCoverSelect(event: any) {
		this.selectedBrandCover = event.target.files[0];
		if (event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (_event) => {
				this.brandCoverPreview = reader.result;
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	}
}
