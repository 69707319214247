import { Component, OnInit } from '@angular/core';
import { Api } from 'src/app/const/api';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { AddForumCategoryComponent } from '../add-forum-category/add-forum-category.component';
import { TopicModeratorComponent } from '../topic-moderator/topic-moderator.component';
import { DialogService } from 'primeng/dynamicdialog';
import { TableColumn } from 'src/app/interfaces/table-column';
import { FieldsType } from 'src/app/enums/fields-type.enum';

@Component({
	selector: 'app-forum-categories',
	templateUrl: './forum-categories.component.html',
	styleUrls: ['./forum-categories.component.scss'],
})
export class ForumCategoriesComponent implements OnInit {
	columns: TableColumn[] = [
		{
			field: 'forum_category_name',
			header: 'Category Name',
			disableSort: false,
			fieldType: FieldsType.Text,
			width: '180px',
		},
		{
			field: 'moderators',
			header: 'Moderators',
			disableSort: false,
			fieldType: FieldsType.Text,
			width: '400px',
			class: 'break-words'
		},
		{
			field: 'forum_category_color',
			header: `Category Color Code (Visible to users on website)`,
			disableSort: false,
			fieldType: FieldsType.Text,
			width: '180px',
			class: 'break-words'
		},
		{
			field: 'action',
			header: 'Action',
			disableSort: true,
			width: '100px',
			fieldType: FieldsType.Action,
		},
	];
	categories: any[] = [];
	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private dialogService: DialogService,
	) { }

	ngOnInit(): void {
		this.getCategories();
	}

	getCategories() {
		this.utilityService.showLoading();
		this.http.get(Api.FORUM_CATEGORIES).subscribe({
			next: (res: any) => {
				res.map((c: any) => {
					c.moderators = c.moderators.toString().replaceAll(',', ', ');
				});
				this.categories = res;
				this.utilityService.hideLoading();
			},
			error: (err) => {
				this.utilityService.hideLoading();
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	deleteCategory = (categoryId: string, index: number) => {
		this.http.delete(Api.DELETE_FORUM_CATEGORY + categoryId).subscribe({
			next: (res: any) => {
				this.categories.splice(index, 1);
			},
			error: (err: any) => {
				this.utilityService.showSuccessToast('Failed to remove category.');
			},
			complete: () => {
				this.utilityService.showSuccessToast('Category Removed.');
			},
		});
	};

	openAddCategoryDialog() {
		const dialogRef = this.dialogService.open(AddForumCategoryComponent, {
			header: 'Add Forum Category',
			width: '350px',
			data: {
				type: 'ADD',
			},
		});

		dialogRef.onClose.subscribe((result) => {
			this.getCategories();
		});
	}

	editCategory = (categoryData, index) => {
		const dialogRef = this.dialogService.open(AddForumCategoryComponent, {
			width: '350px',
			header: 'Edit Category',
			data: {
				type: 'EDIT',
				data: categoryData,
			},
		});

		dialogRef.onClose.subscribe((result) => {
			this.getCategories();
		});
	};

	manageTopicModerator = (categoryData: any, index: number) => {
		const dialogRef = this.dialogService.open(TopicModeratorComponent, {
			width: '50%',
			header: 'Manage Moderators',
			data: {
				categoryId: categoryData.id,
				categoryName: categoryData.forum_category_name,
			},
		});

		dialogRef.onClose.subscribe((result) => {
			this.getCategories();
		});
	};
}
