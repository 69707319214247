<div class="page">
	<app-page-header header="Brands">
		<button pButton (click)="openAddBrandDialog()">
			<span class="material-symbols-rounded"> add </span>
			Add Brand
		</button>
	</app-page-header>

	<div class="category-container">
		<app-simple-table [columns]="columns" [data]="brands" [paginator]="true" [showFilter]="false"
			[showActions]="true" [showEditAction]="true" [showDeleteAction]="true" [onRowClick]="editBrand"
			[onEdit]="editBrand" [onDelete]="deleteBrand">
		</app-simple-table>
	</div>
</div>