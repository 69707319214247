import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings/settings.component';
import { RouterModule } from '@angular/router';
import { SettingRoutingModule } from './setting-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaqComponent } from './faq/faq.component';

// CK Editor
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { AccordionModule } from 'primeng/accordion';
import { ManageFaqComponent } from './manage-faq/manage-faq.component';
import { FieldWithLabelComponent } from 'src/app/components/field-with-label/field-with-label.component';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';

import { WebsiteSettingsComponent } from './website-settings/website-settings.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
	declarations: [SettingsComponent, WebsiteSettingsComponent, FaqComponent, ManageFaqComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(SettingRoutingModule),
		FormsModule,
		ReactiveFormsModule,
		CKEditorModule,
		PageHeaderComponent,
		AccordionModule,
		FieldWithLabelComponent,
		DropdownModule,
		ButtonModule,
		InputNumberModule, TooltipModule, InputTextModule
	],
})
export class SettingsModule { }
