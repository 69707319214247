import { Injectable } from '@angular/core';
import { Api } from 'src/app/const/api';
import { HttpService } from '../http/http.service';

@Injectable({
	providedIn: 'root',
})
export class CommonApiCallService {
	constructor(private http: HttpService) {}

	getCategories() {
		return this.http.get(Api.GET_CATEGORIES);
	}

	updateProduct(productId: string, params: object) {
		return this.http.patch(Api.UPDATE_PRODUCT(productId), params);
	}
}
