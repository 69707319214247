import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Api } from 'src/app/const/api';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { TableColumn } from 'src/app/interfaces/table-column';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-order-details',
	templateUrl: './order-details.component.html',
	styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
	orderId: string;
	orderData: any;

	imageUrl: string = environment.imageUrl;
	orderItemsColumns: TableColumn[] = [
		{
			field: 'product_name',
			header: 'Item',
			fieldType: FieldsType.Text,
			disableSort: false,
		},
		{
			field: 'product_sku',
			header: 'Product SKU',
			fieldType: FieldsType.Text,
			disableSort: false,
		},
		{
			field: 'product_variant',
			header: 'Product Name',
			fieldType: FieldsType.Text,
			disableSort: false,
		},
		{
			field: 'quantity',
			header: 'Quantity',
			fieldType: FieldsType.Text,
			disableSort: false,
		},
		{
			field: 'item_price',
			header: 'Price',
			fieldType: FieldsType.Number,
			disableSort: false,
			prefix: '$',
			suffix: '',
		},
		{
			field: 'item_discount_price',
			header: 'Discount Price',
			fieldType: FieldsType.Number,
			disableSort: false,
			prefix: '$',
			suffix: '',
		},
		{
			field: 'item_weight_g',
			header: 'Weight(G)',
			fieldType: FieldsType.Number,
			disableSort: false,
			prefix: '',
			suffix: '',
		},
		{
			field: 'item_weight_o',
			header: 'Weight(OZ)',
			fieldType: FieldsType.Number,
			disableSort: false,
			prefix: '',
			suffix: '',
		},
		{
			field: 'supplier',
			header: 'Supplier',
			fieldType: FieldsType.Text,
			disableSort: false,
		},
		{
			field: 'category_name',
			header: 'Category',
			fieldType: FieldsType.Text,
			disableSort: false,
		},
		{
			field: 'brand_name',
			header: 'Brand',
			fieldType: FieldsType.Text,
			disableSort: false,
		},
	];

	orderItems: any[] = [];

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private route: ActivatedRoute,
	) {
		this.route.params.subscribe((params) => {
			this.orderId = params['orderId'];
		});
	}

	ngOnInit(): void {
		this.getOrderDetails();
	}

	getOrderDetails() {
		this.http.get(Api.GET_ORDER_DETAILS(this.orderId)).subscribe({
			next: (res: any) => {
				this.orderData = res;
				this.orderItems = res.order_items;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}
}
