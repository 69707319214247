import { Component, OnInit } from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';

import { Api } from 'src/app/const/api';
import { ApiMessages } from 'src/app/const/messages';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
	selector: 'app-shipping-charges',
	templateUrl: './shipping-charges.component.html',
	styleUrls: ['./shipping-charges.component.scss'],
})
export class ShippingChargesComponent implements OnInit {
	shippingChargesFrom: UntypedFormGroup;

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private formBuilder: UntypedFormBuilder,
	) {
		this.shippingChargesFrom = new UntypedFormGroup({
			shippingCharges: this.formBuilder.array([this.shippingChargesFormGroup()]),
		});
	}

	ngOnInit(): void {
		this.getShippingCharges();
	}

	shippingChargesFormGroup(): UntypedFormGroup {
		return this.formBuilder.group({
			id: new UntypedFormControl(''),
			start_weight: new UntypedFormControl('', [Validators.required]),
			end_weight: new UntypedFormControl('', [Validators.required]),
			cost: new UntypedFormControl('', [Validators.required]),
		});
	}

	get variantArray(): UntypedFormArray {
		return <UntypedFormArray>this.shippingChargesFrom.get('shippingCharges');
	}

	addVariant(): void {
		this.variantArray.push(this.shippingChargesFormGroup());
	}

	removeShippingCharge(index: number): void {
		this.utilityService.showLoading();
		const chargeId = this.variantArray.value[index].id;
		this.http.delete(Api.REMOVE_SHIPPING_CHARGE(chargeId)).subscribe({
			next: (res: any) => {
				this.utilityService.hideLoading();
				this.variantArray.removeAt(index);
			},
			error: (err: any) => {
				this.utilityService.hideLoading();
			},
			complete: () => {},
		});
	}

	getShippingCharges() {
		this.utilityService.showLoading();
		this.http.get(Api.GET_SHIPPING_CHARGES).subscribe({
			next: (res: any) => {
				this.utilityService.hideLoading();
				let existingCharges = [];
				for (const c of res) {
					existingCharges.push(
						this.formBuilder.group({
							id: c.id,
							start_weight: c.start_weight,
							end_weight: c.end_weight,
							cost: c.cost,
						}),
					);
				}

				this.shippingChargesFrom.setControl(
					'shippingCharges',
					this.formBuilder.array(existingCharges),
				);
			},
			error: (err: any) => {
				this.utilityService.hideLoading();
			},
			complete: () => {},
		});
	}

	saveShippingCharges() {
		if (this.shippingChargesFrom.valid) {
			this.utilityService.showLoading();
			this.http
				.post(Api.SAVE_SHIPPING_CHARGES, {
					shippingCharges: this.shippingChargesFrom.value.shippingCharges,
				})
				.subscribe({
					next: (res: any) => {
						this.utilityService.hideLoading();
						this.utilityService.showSuccessToast(ApiMessages.SHIPPING_CHARGES_SAVED);
					},
					error: (err: any) => {
						this.utilityService.hideLoading();
						this.utilityService.showSuccessToast(
							ApiMessages.SHIPPING_MANAGER_EMAIL_SAVE_FAIL,
						);
					},
					complete: () => {},
				});
		} else {
			this.utilityService.showSuccessToast('Invalid Details.');
		}
	}
}
