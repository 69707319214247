<div class="page">
	<app-page-header header="Dashboard"></app-page-header>
	<br />

	<!-- Quick Links Section -->
	<div class="flex"></div>

	<div class="grid grid-cols-4 gap-6">
		<app-statistics-card
			title="Total Registered Users"
			[value]="statisticsData?.total_users"
			icon="person"
			color="#1a191c"
			background="#f5f4f7">
		</app-statistics-card>
		<app-statistics-card
			title="Total Orders"
			[value]="statisticsData?.total_orders"
			icon="shopping_basket"
			color="#1a191c"
			background="#f5f4f7">
		</app-statistics-card>
		<app-statistics-card
			title="Orders This Month"
			[value]="statisticsData?.order_this_month"
			icon="shopping_cart_checkout"
			color="#1a191c"
			background="#f5f4f7">
		</app-statistics-card>

		<app-statistics-card
			title="Orders Previous Month"
			[value]="statisticsData?.order_previous_month"
			icon="local_grocery_store"
			color="#1a191c"
			background="#f5f4f7">
		</app-statistics-card>
	</div>
</div>
