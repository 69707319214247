import { Component, OnInit } from '@angular/core';
import { Api } from 'src/app/const/api';
import { HttpService } from 'src/app/services/http/http.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
	statisticsData: any;
	constructor(private http: HttpService) {}

	ngOnInit(): void {
		this.getStatistics();
	}

	getStatistics() {
		this.http.get(Api.GET_STATISTICS).subscribe({
			next: (res: any) => {
				this.statisticsData = res[0];
			},
			error: (err: any) => {
				console.log(err);
			},
		});
	}
}
