<div class="page">
	<app-page-header header="Order details for order #{{ orderData?.display_id }}">
		<div *ngIf="orderData.is_pre_order == '1'"
			class="text-lg bg-red-700 py-1 px-4 rounded-full text-white font-extrabold">
			This is Pre order
		</div>
	</app-page-header>

	<div class="add-product-container" *ngIf="orderData">
		<div class="border-b border-gray-300 py-2">
			<h2 class="text-xl font-semibold pt-2">Order Details</h2>
			<div class="mt-2">
				<div class="detail-fields">
					<p>Order Subtotal:</p>
					<p>${{ orderData.subtotal }}</p>
				</div>
				<div class="detail-fields">
					<p>Shipping Charge:</p>
					<p>${{ orderData.shipping_charge }}</p>
				</div>
				<div class="detail-fields">
					<p>Order Discount:</p>
					<p>${{ orderData.discount }}</p>
				</div>
				<div class="detail-fields">
					<p>Order Total:</p>
					<p>${{ orderData.total }}</p>
				</div>
				<div class="detail-fields">
					<p>Transaction Id:</p>
					<p>{{ orderData.payment_id }}</p>
				</div>
				<div class="detail-fields">
					<p>Order Status:</p>
					<p class="bg-yellow-400 py-1 px-4 rounded-full text-white min-w-[80px] max-w-min text-center">
						{{ orderData.status }}
					</p>
				</div>
				<div class="detail-fields">
					<p>Payment Status:</p>
					<p class="bg-yellow-400 py-1 px-4 rounded-full text-white min-w-[80px] max-w-min text-center">
						{{ orderData.payment_status }}
					</p>
				</div>
				<div class="detail-fields">
					<p>Pre Order:</p>
					<p class="bg-blue-600 py-1 px-4 rounded-full text-white min-w-[80px] max-w-min text-center">
						{{ orderData.is_pre_order == '1' ? 'Yes' : 'No' }}
					</p>
				</div>
			</div>
		</div>

		<div class="py-2">
			<div class="flex">
				<div class="w-full">
					<div class="flex items-center justify-between">
						<h2 class="text-xl font-semibold pt-2 pb-1">Order Products</h2>
					</div>
					<app-simple-table [columns]="orderItemsColumns" [data]="orderItems" [paginator]="true"
						[showFilter]="false" [showActions]="false">
					</app-simple-table>

				</div>
			</div>
		</div>

		<div class="border-b border-gray-300 py-2">
			<div class="flex">
				<div class="w-full border-r border-gray-300 pr-12">
					<div class="flex items-center justify-between">
						<h2 class="text-xl font-semibold pt-2 pb-1">Order Shipping Address</h2>
					</div>

					<div class="detail-fields">
						<p>Address 1:</p>
						<p>{{ orderData.order_shipping_address.address_1 }}</p>
					</div>

					<div class="detail-fields">
						<p>Address-2:</p>
						<p>{{ orderData.order_shipping_address.address_2 }}</p>
					</div>

					<div class="detail-fields">
						<p>City:</p>
						<p>{{ orderData.order_shipping_address.city }}</p>
					</div>

					<div class="detail-fields">
						<p>State:</p>
						<p>{{ orderData.order_shipping_address.state }}</p>
					</div>

					<div class="detail-fields">
						<p>Country:</p>
						<p>{{ orderData.order_shipping_address.country }}</p>
					</div>

					<div class="detail-fields">
						<p>landmark:</p>
						<p>{{ orderData.order_shipping_address.landmark }}</p>
					</div>
				</div>

				<div class="w-full pl-8">
					<div class="flex items-center justify-between">
						<h2 class="text-xl font-semibold pt-2 pb-1">Order Billing Address</h2>
					</div>

					<div class="detail-fields">
						<p>Address 1:</p>
						<p>{{ orderData.order_billing_address.address_1 }}</p>
					</div>

					<div class="detail-fields">
						<p>Address-2:</p>
						<p>{{ orderData.order_billing_address.address_2 }}</p>
					</div>

					<div class="detail-fields">
						<p>City:</p>
						<p>{{ orderData.order_billing_address.city }}</p>
					</div>

					<div class="detail-fields">
						<p>State:</p>
						<p>{{ orderData.order_billing_address.state }}</p>
					</div>

					<div class="detail-fields">
						<p>Country:</p>
						<p>{{ orderData.order_billing_address.country }}</p>
					</div>

					<div class="detail-fields">
						<p>landmark:</p>
						<p>{{ orderData.order_billing_address.landmark }}</p>
					</div>
				</div>
			</div>
		</div>

		<div class="w-full border-b border-gray-300 py-2">
			<div class="flex items-center justify-between">
				<h2 class="text-xl font-semibold pt-2 pb-1">User Details</h2>
			</div>
			<div class="detail-fields">
				<p>User name:</p>
				<p>{{ orderData.first_name }} {{ orderData.last_name }}</p>
			</div>

			<div class="detail-fields">
				<p>Email:</p>
				<p class="underline">
					<a href="mailto:{{ orderData.email }}">
						{{ orderData.email }}
					</a>
				</p>
			</div>

			<div class="detail-fields">
				<p>Phone:</p>
				<p class="underline">
					<a href="tel:{{ orderData.mobile }}
			  {{ orderData.mobile }}">
						{{ orderData.mobile }}</a>
				</p>
			</div>

			<div class="detail-fields">
				<p>Ordered On:</p>
				<p>{{ orderData.created_at | date : 'medium' }}</p>
			</div>
		</div>
	</div>
</div>