import { CommonModule } from '@angular/common';
import { Component, Input, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { Table, TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { PipesModule } from 'src/app/pipes/pipes.module';

@Component({
	standalone: true,
	selector: 'app-simple-table',
	templateUrl: './simple-table.component.html',
	styleUrls: ['./simple-table.component.scss'],
	imports: [CommonModule, TableModule, ButtonModule, TooltipModule, PipesModule],
})
export class SimpleTableComponent {
	selected: any = [];
	@Input('columns') columns: any;
	@Input('data') data: any;
	@Input('paginator') paginator: boolean = true;
	@Input('rows') rows: number = 20;
	@Input('showFilter') showFilter: boolean = false;

	@Input('showActions') showActions: boolean = false;

	@Input('onRowClick') onRowClick: Function = () => { };

	@Input('showEditAction') showEditAction: boolean = false;
	@Input('onEdit') onEdit: Function = () => { };

	@Input('showDeleteAction') showDeleteAction: boolean = false;
	@Input('onDelete') onDelete: Function = () => { };

	@Input('showExtraAction') showExtraAction: boolean = false;
	@Input('extraActionToolTip') extraActionToolTip: string = '';
	@Input('extraActionIcon') extraActionIcon: string = '';
	@Input('onExtraAction') onExtraAction: Function = () => { };

	@Output('selectedData') selectedData: any;

	get fieldTypes() {
		return FieldsType;
	}

	exportCSV(table: Table) {
		const columns = JSON.parse(JSON.stringify(this.columns));
		delete columns.Action;
		table.columns = columns;
		table.exportCSV();
	}
}
