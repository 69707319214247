<div class="page">
	<app-page-header header="Subscribers">
		<button pButton (click)="exportCSV()">
			<span class="material-symbols-rounded"> download </span>
			Export As CSV
		</button>
		<button pButton (click)="sendEmail()">
			<span class="material-symbols-rounded"> mail </span>
			Email to subscribers
		</button>

		<button pButton (click)="sendIndividualEmail()">
			<span class="material-symbols-rounded"> forward_to_inbox </span>
			Send Email
		</button>
	</app-page-header>

	<div class="product-container">
		<app-simple-table [columns]="columns" [data]="subscribers" [paginator]="true" [showFilter]="false"
			[showActions]="false">
		</app-simple-table>
	</div>
</div>