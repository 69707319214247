<div class="page">
	<app-page-header header="Categories">
		<button pButton (click)="openAddCategoryDialog()">
			<span class="material-symbols-rounded"> add </span>
			Add Category
		</button>
	</app-page-header>

	<div class="category-container">
		<app-simple-table [columns]="columns" [data]="categories" [paginator]="true" [showFilter]="false"
			[showActions]="true" [showEditAction]="true" [showDeleteAction]="true" [onRowClick]="editCategory"
			[onEdit]="editCategory" [onDelete]="deleteCategory">
		</app-simple-table>
	</div>
</div>