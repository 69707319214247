<div class="page">
	<app-page-header header="Products">
		<button pButton [routerLink]="'/add-product'">
			<span class="material-symbols-rounded"> add </span>
			Add Product
		</button>
	</app-page-header>

	<div class="product-container">
		<div class="p-4 rounded shadow-md border border-[#c4c4c4] mt-4">
			<p class="font-semibold text-lg mb-4">Filters</p>
			<div class="flex gap-2 items-center">
				<div class="flex-grow flex gap-2">
					<app-field-with-label title="Category">
						<p-dropdown [options]="categories" [(ngModel)]="filterParams.category_id"
							optionLabel="category_name" optionValue="id" placeholder="Select a Category"></p-dropdown>
					</app-field-with-label>

					<app-field-with-label title="Brand">
						<p-dropdown [options]="brands" [(ngModel)]="filterParams.brand_id" optionLabel="brand_name"
							optionValue="id" placeholder="Select a Brand"></p-dropdown>
					</app-field-with-label>

					<app-field-with-label title="Supplier">
						<p-dropdown [options]="suppliers" [(ngModel)]="filterParams.supplier_id"
							optionLabel="company_name" optionValue="id" placeholder="Select a Supplier"></p-dropdown>
					</app-field-with-label>

				</div>
				<div>
					<button type="button" mat-flat-button color="primary" matTooltip="Apply product filter"
						(click)="filterProducts()">
						Apply Filter
					</button>
				</div>
			</div>
		</div>

		<p-table #table dataKey="id" [columns]="columns" [value]="products" [tableStyle]="{'min-width': '60rem'}"
			[paginator]="true" [rows]="20" [rowsPerPageOptions]="[15, 20, 25, 50]" [selectionMode]="'single'"
			rowGroupMode="subheader" styleClass="p-datatable-striped p-datatable-gridlines text-md" sortMode="multiple">
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th *ngFor="let col of columns"
						[ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
						[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
						[pSortableColumn]="col.field" [pSortableColumnDisabled]="col.disableSort" [class]="col.class">
						<div class="flex items-center">
							{{ col.header }}
							<p-sortIcon [field]="col.field" *ngIf="!col.disableSort"></p-sortIcon>
						</div>
					</th>
				</tr>

				<tr *ngIf="showFilter">
					<th *ngFor="let col of columns"
						[ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
						[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}" [class]="col.class">
						<p-columnFilter type="text" [field]="col.field"
							*ngIf="col.field != 'Action' && col.field != 'Photo' && col.field != 'Image'"></p-columnFilter>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
				<tr class="text-[12px] md:text-[14px]" (click)="editProduct(rowData, rowIndex)">
					<ng-container *ngFor="let col of columns">
						<td class="flex gap-1 items-center" *ngIf="col.fieldType == fieldTypes.Action"
							[class]="col.class">
							<div class="flex gap-1">
								<button pButton pRipple type="button" pTooltip="Edit" icon="pi pi-pencil"
									(click)="$event.stopPropagation(); editProduct(rowData, rowIndex)"
									class="p-button-rounded p-button-text"></button>

								<button pButton pRipple type="button" pTooltip="Delete" icon="pi pi-trash"
									(click)="$event.stopPropagation(); deleteProduct(rowData, rowIndex)"
									class="p-button-rounded p-button-text"></button>

								<!-- <button pButton pRipple type="button" [pTooltip]="extraActionToolTip"
									[icon]="extraActionIcon"
									(click)="$event.stopPropagation(); onExtraAction(rowData, rowIndex)"
									class="p-button-rounded p-button-text" *ngIf="showExtraAction"></button> -->
							</div>
						</td>
						<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == fieldTypes.Text || col.fieldType == fieldTypes.Email"
							[class]="'cursor-pointer ' + col.class"
							[pTooltip]="col.showToolTip ? rowData[col.field]: ''" [autoHide]="false"
							tooltipPosition="bottom">
							{{ rowData[col.field] ? rowData[col.field] : '-'}}
						</td>
						<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							*ngIf="col.fieldType == fieldTypes.Category" [class]="'cursor-pointer text-left '"
							[pTooltip]="col.showToolTip ? rowData[col.field]: ''" [autoHide]="false"
							tooltipPosition="bottom">
							<span class="flex gap-1 items-center">
								<div class="h-[14px] w-[14px] bg-[#c4c4c4]"
									[style.background]="rowData[col.colorField]"></div>
								<span [class]="col.class">
									{{ rowData[col.field]}}
								</span>
							</span>
						</td>
						<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}" *ngIf="
									col.fieldType == fieldTypes.Telephone" [class]="'cursor-pointer ' + col.class"
							[pTooltip]="col.showToolTip ? rowData[col.field]: ''" [autoHide]="false"
							tooltipPosition="bottom">
							{{ rowData[col.field]}}
						</td>
						<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == fieldTypes.Date" [class]="'cursor-pointer'">
							{{ rowData[col.field] | date: ((col.dateFormat) ? col.dateFormat : 'MM/dd/yyyy')}}
						</td>
						<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == fieldTypes.Number" [class]="'cursor-pointer'">
							{{col?.prefix}}{{ (rowData[col.field] ? rowData[col.field]: 0) | number:
							'1.2-2'}}{{col?.suffix}}
						</td>
						<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == fieldTypes.Boolean" [class]="'cursor-pointer ' + col.class">
							{{ rowData[col.field] ? col.trueValue : col.falseValue}}
						</td>
						<td class="cursor-pointer text-blue-500"
							[ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == fieldTypes.Toggle" [class]="'cursor-pointer '+ col.class">
							<p-inputSwitch [trueValue]="'1'" [falseValue]="'0'" (click)="$event.stopPropagation()"
								[(ngModel)]="rowData[col.field]" (onChange)="updateProductStatus($event, rowData.id)">
							</p-inputSwitch>
						</td>
						<td class="cursor-pointer"
							[ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == fieldTypes.Image" [class]="'cursor-pointer'">
							<img [src]="col.imageBaseUrl + rowData[col.field]" [alt]=""
								class="h-14 w-14 max-h-14 max-w-14 rounded-md my-2" />
						</td>
						<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == fieldTypes.DaysAgo" [class]="'cursor-pointer ' + col.class">
							{{(rowData[col.field]) ? (rowData[col.field] | daysAgo) : '-'}}
						</td>
						<td class="cursor-pointer"
							[ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
							[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
							*ngIf="col.fieldType == fieldTypes.ImageGroup" [class]="'cursor-pointer '+ col.class">
							<div class="relative flex gap-1">
								<ng-container *ngFor="let img of rowData[col.field]">
									<img [src]="img[col.imageKey]" [alt]="img[col.imageAltKey]"
										class="h-5 w-5 flex-shrink-0 rounded-full"
										onError="this.src = '/assets/images/placeholder-image.webp'"
										[pTooltip]="img[col.imageAltKey] + ' liked this'" *ngIf="img[col.imageKey]">
									<img [src]="'https://ui-avatars.com/api/?background=random&name=' + img[col.imageAltKey]"
										[alt]="img[col.imageAltKey]" class="h-5 w-5 flex-shrink-0 rounded-full"
										*ngIf="!img[col.imageKey]" />
								</ng-container>
							</div>
						</td>
					</ng-container>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>