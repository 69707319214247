<div class="sign-in-container">
	<div class="form-container">
		<form [formGroup]="signInFrom" (ngSubmit)="signIn()">
			<img class="h-[100px] w-auto mx-auto" src="/assets/images/logo.jpg" alt="Logo">
			<h1 class="my-1 mt-4 text-center">Welcome to Stars & Stripes Seed Co. <br> Sign In to access admin dashboard
			</h1>
			<p-divider></p-divider>
			<div class="flex flex-col gap-4">
				<div class="flex flex-col gap-2">
					<label for="email">Email</label>
					<input pInputText id="email" type="email" formControlName="email" required />
					<small class="error" *ngIf="email.invalid && (email.dirty || email?.touched)">
						<span *ngIf="email?.errors?.['required']">* Email is required </span>
						<span *ngIf="email.errors?.['email']">* Email is invalid </span>
					</small>
				</div>

				<div class="flex flex-col gap-2">
					<label for="password">Password</label>
					<input pInputText id="password" type="password" formControlName="password" required />
					<small class="error" *ngIf="password.invalid && (password.dirty || password?.touched)">
						<span *ngIf="password?.errors?.['required']">* Password is required </span>
					</small>
				</div>
			</div>
			<div class="my-2">
				<a routerLink="/forget-password" class="underline text-blue-500">Forget password?</a>
			</div>
			<button type="submit" class="w-full" label="Sign In" pButton></button>
		</form>
	</div>
</div>