import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Api } from 'src/app/const/api';
import { ApiMessages } from 'src/app/const/messages';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { environment } from 'src/environments/environment';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormControl, FormGroup } from '@angular/forms';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { TableColumn } from 'src/app/interfaces/table-column';
@Component({
	selector: 'app-supplier-details',
	templateUrl: './supplier-details.component.html',
	styleUrls: ['./supplier-details.component.scss'],
})
export class SupplierDetailsComponent implements OnInit {
	supplierId: string;
	supplierData: any;
	supplierStatus: boolean = false;

	imageUrl: string = environment.imageUrl;

	productColumns: TableColumn[] = [
		{
			field: 'product_main_image',
			header: 'Product Image',
			disableSort: false,
			fieldType: FieldsType.Image,
			imageBaseUrl: environment.imageUrl,
		},
		{
			field: 'product_name',
			header: 'Product Name',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'product_sku',
			header: 'SKU',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'category_name',
			header: 'Category Name',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
	];

	products: any[] = [];
	selectedSupplierPoster: any = [];
	supplierPosterPreview: any;

	selectedSupplierBanner: any = [];
	supplierBannerPreview: any;

	form: FormGroup;
	editor = ClassicEditor;
	editorConfig = {
		placeholder: 'Add your description...',
		toolbar: {
			items: ['heading', '|', 'bold', 'italic'],
		},
	};

	statuses = [
		{
			id: 'A',
			label: 'Active',
		},
		{
			id: 'NA',
			label: 'Not Active',
		},
		{
			id: 'V',
			label: 'Contact for Onboarding',
		},
		{
			id: 'O',
			label: 'Onboarding',
		},
	];
	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private route: ActivatedRoute,
		private router: Router
	) {
		this.route.params.subscribe((params) => {
			this.supplierId = params['supplierId'];
		});

		this.form = new FormGroup({
			status: new FormControl(''),
			supplierDescription: new FormControl(''),
		});
	}

	ngOnInit(): void {
		this.getSupplierDetails();
		this.getSupplierProducts();
	}

	getSupplierDetails() {
		this.http.get(Api.GET_SUPPLIER_DETAILS(this.supplierId)).subscribe({
			next: (res: any) => {
				this.supplierData = res;
				this.form.patchValue({
					status: this.supplierData.status,
					supplierDescription: this.supplierData.description,
				});
				this.supplierPosterPreview = this.supplierData.poster;
				this.supplierBannerPreview = this.supplierData.banner;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	getSupplierProducts() {
		this.utilityService.showLoading();
		this.http.get(Api.GET_SUPPLIERS_PRODUCT(this.supplierId)).subscribe({
			next: (res: any) => {
				this.products = res;
			},
			error: (err) => {
				this.utilityService.hideLoading();
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	onSupplierLogoSelect(event) {
		this.selectedSupplierPoster = event.target.files[0];
		if (event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (_event) => {
				this.supplierPosterPreview = reader.result;
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	}

	removeSupplierLogo() {
		this.supplierPosterPreview = null;
		this.selectedSupplierPoster = null;
	}

	onSupplierCoverSelect(event) {
		this.selectedSupplierBanner = event.target.files[0];
		if (event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (_event) => {
				this.supplierBannerPreview = reader.result;
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	}
	removeSupplierCover() {
		this.selectedSupplierBanner = null;
		this.supplierBannerPreview = null;
	}

	saveSupplierDetails() {
		if (
			this.selectedSupplierPoster ||
			this.selectedSupplierPoster ||
			this.form.value.supplierDescription
		) {
			this.utilityService.showLoading();
			const _formData: any = new FormData();
			const { status, supplierDescription } = this.form.value;

			_formData.append('status', status);
			_formData.append('description', supplierDescription);

			if (this.selectedSupplierPoster && this.selectedSupplierPoster.name) {
				_formData.append(
					'poster',
					this.selectedSupplierPoster,
					this.selectedSupplierPoster.name,
				);
			}

			if (this.selectedSupplierBanner && this.selectedSupplierBanner.name) {
				_formData.append(
					'banner',
					this.selectedSupplierBanner,
					this.selectedSupplierBanner.name,
				);
			}

			this.http.patch(Api.UPDATE_SUPPLIER_STATUS(this.supplierId), _formData).subscribe({
				next: (res: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(ApiMessages.SUPPLIER_DETAILS_UPDATED);
				},
				error: (err: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast('');
				},
			});
		}
	}

	viewProductDetails = (rowData: any) => {
		this.router.navigate(['/edit-product', rowData.id])
	}
}
