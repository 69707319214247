<div class="page">
	<app-page-header header="Categories">
		<button pButton (click)="openAddCategoryDialog()">
			<span class="material-symbols-rounded"> add </span>
			Add Category
		</button>
	</app-page-header>

	<div class="category-container">
		<app-simple-table [columns]="columns" [data]="categories" [paginator]="true" [showFilter]="true"
			[showActions]="true" [showEditAction]="true" [onEdit]="editCategory" [showDeleteAction]="true"
			[onDelete]="deleteCategory" [showExtraAction]="true" [onExtraAction]="manageTopicModerator"
			[extraActionIcon]="'pi pi-user'" [extraActionToolTip]="'Manage Moderator'">
		</app-simple-table>
	</div>
</div>